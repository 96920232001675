import { createSlice } from '@reduxjs/toolkit';
import {
  getAllGalleries,
  getGalleryById,
  addGallery,
  updateGallery,
  deleteGallery,
} from './galleryAsyncActions';
import { history } from '../../../utils/history';
export interface GalleryType {
  gallery_id: number | null;
  title: string;
  subtitle: string;
  description: string;
  media: string;
  media_thumb: string;
  is_main: boolean;
  subcategory_id: number | null;
}
export interface GalleryStateType {
  gallery: GalleryType | null | undefined;
  allGalleries: Array<GalleryType> | null;
  isGettingGallery: boolean;
}
const initialState: GalleryStateType = {
  gallery: null,
  allGalleries: [],
  isGettingGallery: false,
};
export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    initiateGallery: (state) => {
      state.gallery = {
        gallery_id: null,
        title: '',
        subtitle: '',
        description: '',
        media: '',
        media_thumb: '',
        is_main: false,
        subcategory_id: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllGalleries?.pending, (state) => {
      state.isGettingGallery = true;
    });
    builder.addCase(getAllGalleries?.fulfilled, (state, action: any) => {
      state.isGettingGallery = false;
      state.allGalleries = action?.payload?.data.data;
    });
    builder.addCase(getAllGalleries.rejected, (state, action) => {
      state.isGettingGallery = false;
    });
    // getGalleryById
    builder.addCase(getGalleryById?.pending, (state) => {
      state.isGettingGallery = true;
    });
    builder.addCase(getGalleryById?.fulfilled, (state, action: any) => {
      state.isGettingGallery = false;
      state.gallery = action?.payload?.data.data as GalleryType;
    });
    builder.addCase(getGalleryById.rejected, (state, action: any) => {
      state.isGettingGallery = false;
    });
    // addGallery
    builder.addCase(addGallery?.pending, (state) => {
      state.isGettingGallery = true;
    });
    builder.addCase(addGallery?.fulfilled, (state, action: any) => {
      state.isGettingGallery = false;
      history.navigate('/gallery', {
        state: {
          id: null,
          pageTitle: 'Gallery List',
          referencePath: '/gallery',
          referencePageTitle: 'Gallery List',
        },
      });
    });
    builder.addCase(addGallery.rejected, (state, action: any) => {
      state.isGettingGallery = false;
    });
    // updateGallery
    builder.addCase(updateGallery?.pending, (state) => {
      state.isGettingGallery = true;
    });
    builder.addCase(updateGallery?.fulfilled, (state, action: any) => {
      state.isGettingGallery = false;
      history.navigate('/gallery', {
        state: {
          id: null,
          pageTitle: 'Gallery List',
          referencePath: '/gallery',
          referencePageTitle: 'Gallery List',
        },
      });
    });
    builder.addCase(updateGallery.rejected, (state, action: any) => {
      state.isGettingGallery = false;
    });
    // deleteGallery
    builder.addCase(deleteGallery?.pending, (state) => {
      state.isGettingGallery = true;
    });
    builder.addCase(deleteGallery?.fulfilled, (state, action: any) => {
      state.isGettingGallery = false;
      history.navigate('/gallery', {
        state: {
          id: null,
          pageTitle: 'Gallery List',
          referencePath: '/gallery',
          referencePageTitle: 'Gallery List',
        },
      });
    });
    builder.addCase(deleteGallery.rejected, (state, action: any) => {
      state.isGettingGallery = false;
    });
  },
});
export const { initiateGallery } = gallerySlice.actions;
const galleryReducer = gallerySlice.reducer;
export default galleryReducer;
