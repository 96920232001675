import { createSlice } from '@reduxjs/toolkit';
import {
  getAllSubcategory,
  getSubcategoryById,
  addSubcategory,
  updateSubcategory,
  deleteSubcategory,
  getCategories,
} from './subcategoryAsyncActions';
import { history } from '../../../utils/history';
// import { AxiosResponse } from 'axios';

// type AllSubcategoryActionType = PayloadAction<
//   AxiosResponse<any, any> | undefined,
//   string,
//   { arg: void; subcategoryId: string; subcategoryStatus: 'fulfilled' },
//   never
// >;

export interface CategoryType {
  category_id?: number | null;
  category_name: string;
}
export interface SubcategoryType {
  subcategory_id?: number | null;
  subcategory_name: string;
  subcategory_description: string;
  category_id: number | null;
}
export interface SubcategoryStateType {
  subcategory: SubcategoryType | null | undefined;
  allSubcategory: Array<SubcategoryType> | null;
  allCategories: Array<CategoryType> | [];
  isGettingSubcategory: boolean;
}
const initialState: SubcategoryStateType = {
  subcategory: null,
  allSubcategory: [],
  allCategories: [],
  isGettingSubcategory: false,
};
export const subcategorySlice = createSlice({
  name: 'subcategory',
  initialState,
  reducers: {
    initiateSubcategory: (state) => {
      state.subcategory = {
        subcategory_id: null,
        subcategory_name: '',
        subcategory_description: '',
        category_id: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories?.pending, (state) => {
      state.isGettingSubcategory = true;
    });
    builder.addCase(getCategories?.fulfilled, (state, action: any) => {
      state.isGettingSubcategory = false;
      state.allCategories = action?.payload?.data.data;
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      state.isGettingSubcategory = false;
    });
    // get all categories
    builder.addCase(getAllSubcategory?.pending, (state) => {
      state.isGettingSubcategory = true;
    });
    builder.addCase(getAllSubcategory?.fulfilled, (state, action: any) => {
      state.isGettingSubcategory = false;
      state.allSubcategory = action?.payload?.data.data;
    });
    builder.addCase(getAllSubcategory.rejected, (state, action) => {
      state.isGettingSubcategory = false;
    });
    // getSubcategoryById
    builder.addCase(getSubcategoryById?.pending, (state) => {
      state.isGettingSubcategory = true;
    });
    builder.addCase(getSubcategoryById?.fulfilled, (state, action: any) => {
      state.isGettingSubcategory = false;
      state.subcategory = action?.payload?.data.data as SubcategoryType;
    });
    builder.addCase(getSubcategoryById.rejected, (state, action: any) => {
      state.isGettingSubcategory = false;
    });
    // addSubcategory
    builder.addCase(addSubcategory?.pending, (state) => {
      state.isGettingSubcategory = true;
    });
    builder.addCase(addSubcategory?.fulfilled, (state, action: any) => {
      state.isGettingSubcategory = false;
      history.navigate('/subcategory', {
        state: {
          id: null,
          pageTitle: 'Subcategory List',
          referencePath: '/subcategory',
          referencePageTitle: 'Subcategory List',
        },
      });
    });
    builder.addCase(addSubcategory.rejected, (state, action: any) => {
      state.isGettingSubcategory = false;
    });
    // updateSubcategory
    builder.addCase(updateSubcategory?.pending, (state) => {
      state.isGettingSubcategory = true;
    });
    builder.addCase(updateSubcategory?.fulfilled, (state, action: any) => {
      state.isGettingSubcategory = false;
      history.navigate('/subcategory', {
        state: {
          id: null,
          pageTitle: 'Subcategory List',
          referencePath: '/subcategory',
          referencePageTitle: 'Subcategory List',
        },
      });
    });
    builder.addCase(updateSubcategory.rejected, (state, action: any) => {
      state.isGettingSubcategory = false;
    });
    // deleteSubcategory
    builder.addCase(deleteSubcategory?.pending, (state) => {
      state.isGettingSubcategory = true;
    });
    builder.addCase(deleteSubcategory?.fulfilled, (state, action: any) => {
      state.isGettingSubcategory = false;
      history.navigate('/subcategory', {
        state: {
          id: null,
          pageTitle: 'Subcategory List',
          referencePath: '/subcategory',
          referencePageTitle: 'Subcategory List',
        },
      });
    });
    builder.addCase(deleteSubcategory.rejected, (state, action: any) => {
      state.isGettingSubcategory = false;
    });
  },
});
export const { initiateSubcategory } = subcategorySlice.actions;
const subcategoryReducer = subcategorySlice.reducer;
export default subcategoryReducer;
