import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 380px;
  border-radius: 15px;
  background-color: #f0f0f0;
`;

export const LoginImgWrapper = styled.div`
  min-height: 200px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-start-start-radius: 15px;
  border-start-end-radius: 15px;
`;
export const LogoImg = styled.img`
  height: 150px;
`;
export const LoginText = styled.div`
  padding: 20px 15px;
`;
export const ButtonRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px;
  align-items: center;
`;
export const FieldWrapper = styled.div`
  margin-bottom: 30px;
`;

export const Title = styled.h1`
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
`;

export const Label = styled.div`
  width: 100%;
  /* background-color: #b6d3b6; */
  padding: 0px 5px 5px 0px;
  margin: 0;
  /* 
  margin-bottom:20px: */
`;
