import React, { useEffect, useState } from 'react';
import { Wrapper } from './subcategoryListContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getAllSubcategory } from '../subcategoryAsyncActions';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../../components/CustomTable';
import CustomConfirmationModal from '../../../../components/CustomConfirmationModal';
import { deleteSubcategory } from '../subcategoryAsyncActions';

function SubcategoryListContainer() {
  const navigate = useNavigate();
  const columnHeaders = ['ID', 'Subcategory', 'Delete'];
  const columnNames = ['subcategory_id', 'subcategory_name', ''];
  const columnTypes = ['string', 'string', 'delete'];
  const columnWidth = ['10%', '82%', '8%'];
  const searchFileds = ['subcategory_name'];
  const columnHeaderIsSortableList = [true, true, false];
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>();
  const { allSubcategory } = useAppSelector(
    (state) => state.subcategoryReducer
  );
  useEffect(() => {
    dispatch(getAllSubcategory());
  }, [dispatch]);
  const handleClickRow = (item: any) => {
    navigate('/subcategory-detail', {
      state: {
        id: item?.subcategory_id?.toString(),
        pageTitle: 'Edit Subcategory',
        referencePath: '/subcategory',
        referencePageTitle: 'Subcategory List',
      },
    });
  };
  const handleButtonClick = () => {
    navigate('/subcategory-detail', {
      state: {
        id: null,
        pageTitle: 'Create Subcategory',
        referencePath: '/subcategory',
        referencePageTitle: 'Subcategory List',
      },
    });
  };
  const handleDeleteItem = (item: any) => {
    setSelectedDeleteId(item.subcategory_id);
    setIsOpen(true);
  };
  const handleSubmitDelete = () => {
    if (selectedDeleteId) {
      dispatch(deleteSubcategory(selectedDeleteId));
    }
    setIsOpen(false);
  };
  return (
    <Wrapper>
      <CustomTable
        dataList={allSubcategory}
        columnHeaders={columnHeaders}
        columnHeaderIsSortableList={columnHeaderIsSortableList}
        columnNames={columnNames}
        columnWidth={columnWidth}
        columnTypes={columnTypes}
        placeholder='Enter type'
        handleClickRow={handleClickRow}
        handleButtonClick={handleButtonClick}
        buttonText={'Create Subcategory'}
        searchFields={searchFileds}
        handleDeleteItem={handleDeleteItem}
      />
      {isOpen && (
        <CustomConfirmationModal
          title={'Confirm Delete Subcategory '}
          message={
            'By clicking confiming this subcategory will be removed permanently. \nIf you are sure press confirm.'
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleAction={handleSubmitDelete}
        />
      )}
    </Wrapper>
  );
}

export default SubcategoryListContainer;
