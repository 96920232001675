import CustomButton from '../../../../components/CustomButton';
import transformToFormData from '../../../../utils/transformToFormData';
import { addADS, updateADS } from '../adsAsyncActions';
import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  Wrapper,
  FormTitle,
  FieldsWrapper,
  FieldWrapper,
  HeaderWrapper,
  ButtonWrapper,
  FieldsContainer,
} from './adsDetailFormStyles';
import { ADSType } from '../adsSlice';
import { AppDispatch } from '../../../../store/store';
import { useAppDispatch } from '../../../../store/reduxHooks';
interface ADSDetailFormPropsType {
  ads?: ADSType | null;
  location: { pathname: string; state: any };
}
interface FormValues {
  ads_id?: number | null;
  font_size1: string | null;
  font_size2: string | null;
  font_size3: string | null;
  font_size4: string | null;
  font_size5: string | null;
  font_family1: string | null;
  font_family2: string | null;
  font_family3: string | null;
  font_family4: string | null;
  font_family5: string | null;
  color1: string | null;
  color2: string | null;
  color3: string | null;
  color4: string | null;
  color5: string | null;
  font_weight1: string | null;
  font_weight2: string | null;
  font_weight3: string | null;
  font_weight4: string | null;
  font_weight5: string | null;
  margin1: string | null;
  margin2: string | null;
  margin3: string | null;
  margin4: string | null;
  margin5: string | null;
  is_blinking1: boolean | false;
  is_blinking2: boolean | false;
  is_blinking3: boolean | false;
  is_blinking4: boolean | false;
  is_blinking5: boolean | false;
  des1: string | null;
  des2: string | null;
  des3: string | null;
  des4: string | null;
  des5: string | null;
}

interface MyFormProps {
  ads?: ADSType | null;
  dispatch: AppDispatch;
  location?: { pathname: string; state: any };
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
interface OtherPropsType {
  ads?: ADSType | null;
  location?: { pathname: string; state: any };
  dispatch: AppDispatch;
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
const InnerForm = (props: OtherPropsType & FormikProps<FormValues>) => {
  const {
    // formik props
    values,
    errors,
    dirty,
    // component props
    handleChange,
    handleSubmit,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      {/* <>{console.log('valuse', values)}</> */}
      <HeaderWrapper>
        <FormTitle id='alert-dialog-title'></FormTitle>
      </HeaderWrapper>
      <FieldsWrapper>
        {props.ads?.ads_id && (
          <FieldWrapper width='300px'>
            <TextField
              type='text'
              size='small'
              label='ADS ID'
              fullWidth
              value={props.ads?.ads_id}
              disabled
            />
          </FieldWrapper>
        )}
      </FieldsWrapper>
      <FieldsContainer>
        <FieldsWrapper>
          <FieldWrapper>
            <TextField
              required
              type='text'
              fullWidth
              multiline
              rows={4}
              size='small'
              name='des1'
              label='Text 1'
              value={values.des1}
              onChange={handleChange}
              error={errors?.des1 ? true : false}
              helperText={errors?.des1}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='margin1'
              label='Margin'
              value={values.margin1}
              onChange={handleChange}
              error={errors?.margin1 ? true : false}
              helperText={errors?.margin1}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_family1'
              label='Font Family'
              value={values.font_family1}
              onChange={handleChange}
              error={errors?.font_family1 ? true : false}
              helperText={errors?.font_family1}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_size1'
              label='Font Size'
              value={values.font_size1}
              onChange={handleChange}
              error={errors?.font_size1 ? true : false}
              helperText={errors?.font_size1}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_weight1'
              label='Font Weight'
              value={values.font_weight1}
              onChange={handleChange}
              error={errors?.font_weight1 ? true : false}
              helperText={errors?.font_weight1}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='color1'
              label='Color'
              value={values.color1}
              onChange={handleChange}
              error={errors?.color1 ? true : false}
              helperText={errors?.color1}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FormControlLabel
              name='is_blinking1'
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: '#000',
                    },
                  }}
                />
              }
              checked={values.is_blinking1}
              onChange={handleChange}
              label='Blinking?'
            />
          </FieldWrapper>
        </FieldsWrapper>
        <FieldsWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              multiline
              rows={4}
              size='small'
              name='des2'
              label='Text 2'
              value={values.des2}
              onChange={handleChange}
              error={errors?.des2 ? true : false}
              helperText={errors?.des2}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='margin2'
              label='Margin'
              value={values.margin2}
              onChange={handleChange}
              error={errors?.margin2 ? true : false}
              helperText={errors?.margin2}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_family2'
              label='Font Family'
              value={values.font_family2}
              onChange={handleChange}
              error={errors?.font_family2 ? true : false}
              helperText={errors?.font_family2}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_size2'
              label='Font Size'
              value={values.font_size2}
              onChange={handleChange}
              error={errors?.font_size2 ? true : false}
              helperText={errors?.font_size2}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_weight2'
              label='Font Weight'
              value={values.font_weight2}
              onChange={handleChange}
              error={errors?.font_weight2 ? true : false}
              helperText={errors?.font_weight2}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='color2'
              label='Color'
              value={values.color2}
              onChange={handleChange}
              error={errors?.color2 ? true : false}
              helperText={errors?.color2}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FormControlLabel
              name='is_blinking2'
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: '#000',
                    },
                  }}
                />
              }
              checked={values.is_blinking2}
              onChange={handleChange}
              label='Blinking?'
            />
          </FieldWrapper>
        </FieldsWrapper>
        <FieldsWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              multiline
              rows={4}
              size='small'
              name='des3'
              label='Text 3'
              value={values.des3}
              onChange={handleChange}
              error={errors?.des3 ? true : false}
              helperText={errors?.des3}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='margin3'
              label='Margin'
              value={values.margin3}
              onChange={handleChange}
              error={errors?.margin3 ? true : false}
              helperText={errors?.margin3}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_family3'
              label='Font Family'
              value={values.font_family3}
              onChange={handleChange}
              error={errors?.font_family3 ? true : false}
              helperText={errors?.font_family3}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_size3'
              label='Font Size'
              value={values.font_size3}
              onChange={handleChange}
              error={errors?.font_size3 ? true : false}
              helperText={errors?.font_size3}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_weight3'
              label='Font Weight'
              value={values.font_weight3}
              onChange={handleChange}
              error={errors?.font_weight3 ? true : false}
              helperText={errors?.font_weight3}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='color3'
              label='Color'
              value={values.color3}
              onChange={handleChange}
              error={errors?.color3 ? true : false}
              helperText={errors?.color3}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FormControlLabel
              name='is_blinking3'
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: '#000',
                    },
                  }}
                />
              }
              checked={values.is_blinking3}
              onChange={handleChange}
              label='Blinking?'
            />
          </FieldWrapper>
        </FieldsWrapper>
        <FieldsWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              multiline
              rows={4}
              size='small'
              name='des4'
              label='Text 4'
              value={values.des4}
              onChange={handleChange}
              error={errors?.des4 ? true : false}
              helperText={errors?.des4}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='margin4'
              label='Margin'
              value={values.margin4}
              onChange={handleChange}
              error={errors?.margin4 ? true : false}
              helperText={errors?.margin4}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_family4'
              label='Font Family'
              value={values.font_family4}
              onChange={handleChange}
              error={errors?.font_family4 ? true : false}
              helperText={errors?.font_family4}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_size4'
              label='Font Size'
              value={values.font_size4}
              onChange={handleChange}
              error={errors?.font_size4 ? true : false}
              helperText={errors?.font_size4}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_weight4'
              label='Font Weight'
              value={values.font_weight4}
              onChange={handleChange}
              error={errors?.font_weight4 ? true : false}
              helperText={errors?.font_weight4}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='color4'
              label='Color'
              value={values.color4}
              onChange={handleChange}
              error={errors?.color4 ? true : false}
              helperText={errors?.color4}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FormControlLabel
              name='is_blinking4'
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: '#000',
                    },
                  }}
                />
              }
              checked={values.is_blinking4}
              onChange={handleChange}
              label='Blinking?'
            />
          </FieldWrapper>
        </FieldsWrapper>
        <FieldsWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              multiline
              rows={4}
              size='small'
              name='des5'
              label='Text 5'
              value={values.des5}
              onChange={handleChange}
              error={errors?.des5 ? true : false}
              helperText={errors?.des5}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='margin5'
              label='Margin'
              value={values.margin5}
              onChange={handleChange}
              error={errors?.margin5 ? true : false}
              helperText={errors?.margin5}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_family5'
              label='Font Family'
              value={values.font_family5}
              onChange={handleChange}
              error={errors?.font_family5 ? true : false}
              helperText={errors?.font_family5}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_size5'
              label='Font Size'
              value={values.font_size5}
              onChange={handleChange}
              error={errors?.font_size5 ? true : false}
              helperText={errors?.font_size5}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='font_weight5'
              label='Font Weight'
              value={values.font_weight5}
              onChange={handleChange}
              error={errors?.font_weight5 ? true : false}
              helperText={errors?.font_weight5}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              size='small'
              name='color5'
              label='Color'
              value={values.color5}
              onChange={handleChange}
              error={errors?.color5 ? true : false}
              helperText={errors?.color5}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FormControlLabel
              name='is_blinking5'
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: '#000',
                    },
                  }}
                />
              }
              checked={values.is_blinking5}
              onChange={handleChange}
              label='Blinking?'
            />
          </FieldWrapper>
        </FieldsWrapper>
      </FieldsContainer>
      <ButtonWrapper>
        <CustomButton
          type='submit'
          disabled={Object.keys(errors).length > 0 || !dirty}
        >
          Submit ADS
        </CustomButton>
      </ButtonWrapper>
    </form>
  );
};
const validationSchema = Yup.object().shape({
  des1: Yup.string().required('Required'),
});
const ADSForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: (props) => ({
    font_size1: props.ads?.font_size1 || '',
    font_size2: props.ads?.font_size2 || '',
    font_size3: props.ads?.font_size3 || '',
    font_size4: props.ads?.font_size4 || '',
    font_size5: props.ads?.font_size5 || '',
    font_family1: props.ads?.font_family1 || '',
    font_family2: props.ads?.font_family2 || '',
    font_family3: props.ads?.font_family3 || '',
    font_family4: props.ads?.font_family4 || '',
    font_family5: props.ads?.font_family5 || '',
    color1: props.ads?.color1 || '',
    color2: props.ads?.color2 || '',
    color3: props.ads?.color3 || '',
    color4: props.ads?.color4 || '',
    color5: props.ads?.color5 || '',
    font_weight1: props.ads?.font_weight1 || '',
    font_weight2: props.ads?.font_weight2 || '',
    font_weight3: props.ads?.font_weight3 || '',
    font_weight4: props.ads?.font_weight4 || '',
    font_weight5: props.ads?.font_weight5 || '',
    margin1: props.ads?.margin1 || '',
    margin2: props.ads?.margin2 || '',
    margin3: props.ads?.margin3 || '',
    margin4: props.ads?.margin4 || '',
    margin5: props.ads?.margin5 || '',
    is_blinking1: props.ads?.is_blinking1 || false,
    is_blinking2: props.ads?.is_blinking2 || false,
    is_blinking3: props.ads?.is_blinking3 || false,
    is_blinking4: props.ads?.is_blinking4 || false,
    is_blinking5: props.ads?.is_blinking5 || false,
    des1: props.ads?.des1 || '',
    des2: props.ads?.des2 || '',
    des3: props.ads?.des3 || '',
    des4: props.ads?.des4 || '',
    des5: props.ads?.des5 || '',
  }),
  validationSchema,
  enableReinitialize: true,
  async handleSubmit(values: FormValues, { props }) {
    let newValues = { ...values };
    var bodyFormData = new FormData();
    var newFormData = props.transformToFormData(newValues, bodyFormData, null);
    if (props.ads?.ads_id) {
      const ads = { id: props.ads?.ads_id, payload: newFormData };
      props.dispatch(updateADS(ads));
    } else {
      const ads = newFormData;
      props.dispatch(addADS(ads));
    }
  },
})(InnerForm);
function ADSDetailForm({ ads, location }: ADSDetailFormPropsType) {
  const dispatch = useAppDispatch();

  return (
    <Wrapper>
      <ADSForm
        transformToFormData={transformToFormData}
        location={location}
        ads={ads}
        dispatch={dispatch}
      />
    </Wrapper>
  );
}

export default ADSDetailForm;
