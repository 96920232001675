import React, { useEffect, useState } from 'react';
import { Wrapper } from './reviewListContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import {} from '../reviewAsyncActions';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../../components/CustomTable';
import CustomConfirmationModal from '../../../../components/CustomConfirmationModal';
import { getAllReviews, deleteReview } from '../reviewAsyncActions';
function ReviewListContainer() {
  const navigate = useNavigate();
  const columnHeaders = ['ID', 'Reviwer', 'Source', 'Approved?', 'Delete'];
  const columnNames = [
    'review_id',
    'review_owner',
    'source',
    'is_approved',
    '',
  ];
  const columnTypes = ['string', 'string', 'string', 'boolean', 'delete'];
  const columnWidth = ['60px', 'auto', '110px', '110px', '70px'];
  const searchFileds = ['review_owner', 'description'];
  const columnHeaderIsSortableList = [true, true, true, true, false];
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>();
  const { allReviews } = useAppSelector((state) => state.reviewReducer);
  useEffect(() => {
    dispatch(getAllReviews());
  }, [dispatch]);
  const handleClickRow = (item: any) => {
    navigate('/review-detail', {
      state: {
        id: item?.review_id?.toString(),
        pageTitle: 'Edit Review',
        referencePath: '/review',
        referencePageTitle: 'Review List',
      },
    });
  };
  const handleButtonClick = () => {
    navigate('/review-detail', {
      state: {
        id: null,
        pageTitle: 'Create Review',
        referencePath: '/review',
        referencePageTitle: 'Review List',
      },
    });
  };
  const handleDeleteItem = (item: any) => {
    setSelectedDeleteId(item.review_id);
    setIsOpen(true);
  };
  const handleSubmitDelete = () => {
    if (selectedDeleteId) {
      dispatch(deleteReview(selectedDeleteId));
    }
    setIsOpen(false);
  };
  return (
    <Wrapper>
      <CustomTable
        dataList={allReviews}
        columnHeaders={columnHeaders}
        columnHeaderIsSortableList={columnHeaderIsSortableList}
        columnNames={columnNames}
        columnWidth={columnWidth}
        columnTypes={columnTypes}
        placeholder='Enter type'
        handleClickRow={handleClickRow}
        handleButtonClick={handleButtonClick}
        buttonText={'Create Review'}
        searchFields={searchFileds}
        handleDeleteItem={handleDeleteItem}
        // buttonIsDisabled={true}
      />
      {isOpen && (
        <CustomConfirmationModal
          title={'Confirm Delete Review'}
          message={
            'By clicking confiming this review will be removed permanently. \nIf you are sure press confirm.'
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleAction={handleSubmitDelete}
        />
      )}
    </Wrapper>
  );
}

export default ReviewListContainer;
