import * as React from 'react';
import { useState } from 'react';
import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import CustomButton from '../../components/CustomButton';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { login } from './authAsyncActions';
import {
  Wrapper,
  LoginImgWrapper,
  LoginText,
  ButtonRowWrapper,
  FieldWrapper,
  Title,
  LogoImg,
} from './LoginContainerStyles';
interface LoginObjType {
  username: string;
  password: string;
}
const initialLogin = {
  username: '',
  password: '',
};
const LoginContainer = () => {
  const dispatch = useAppDispatch();
  // const { allRequests, isGettingReqsuet } = useAppSelector(
  //   (state) => state.requestReducer
  // );
  const [loginObj, setLoginObj] = useState<LoginObjType>(initialLogin);
  const [error, setError] = useState<string>('');

  const isEmailValid = (email: string) => {
    const isValidEmail = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      ? true
      : false;
    return isValidEmail;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newLogin = { ...loginObj };
    if (name === 'username') {
      const isValid = isEmailValid(value);
      if (isValid) {
        setError('');
      } else {
        setError('Not valid email');
      }
    }
    newLogin[name as keyof LoginObjType] = value;
    setLoginObj(newLogin);
  };
  const handleLogin = () => {
    dispatch(login(loginObj));
  };

  return (
    <Wrapper>
      <LoginImgWrapper>
        <LogoImg src='images/vertical-fnal-logo-both-small.webp' />
      </LoginImgWrapper>
      <LoginText>
        <Title>Welcome to prrtal</Title>
        <FieldWrapper>
          <TextField
            type='text'
            fullWidth
            size='small'
            name='username'
            label='Email *'
            placeholder='Email'
            value={loginObj.username}
            onChange={handleChange}
            error={!loginObj.username ? true : false}
            helperText={error}
            sx={{
              backgroundColor: '#fff',
              // border: '3px solid #001d3d',
            }}
          />
          {/* {error && <div>{error}</div>} */}
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            type='text'
            fullWidth
            size='small'
            name='password'
            label='Password *'
            placeholder='Password'
            value={loginObj.password}
            onChange={handleChange}
            sx={{
              backgroundColor: '#fff',
              // border: '3px solid #001d3d',
            }}
          />
        </FieldWrapper>
        <ButtonRowWrapper>
          <FormControlLabel
            // checked={false}
            control={<Checkbox />}
            label='Remember Me'
          />
          <CustomButton
            onClick={handleLogin}
            disabled={
              !loginObj.username || !loginObj.password || error ? true : false
            }
          >
            Login
          </CustomButton>
        </ButtonRowWrapper>
      </LoginText>
    </Wrapper>
  );
};

export default LoginContainer;
