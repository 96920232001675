import CustomButton from '../../../../components/CustomButton';
import transformToFormData from '../../../../utils/transformToFormData';
import { addWelcome, updateWelcome } from '../welcomeAsyncActions';
import { TextField } from '@mui/material';
import ModalImage from 'react-modal-image';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  Wrapper,
  FormTitle,
  FieldsWrapper,
  FieldWrapper,
  HeaderWrapper,
  ButtonWrapper,
  CustomerImgWrapper,
} from './welcomeDetailFormStyles';
import { WelcomeType } from '../welcomeSlice';
import { AppDispatch } from '../../../../store/store';
import { useAppDispatch } from '../../../../store/reduxHooks';
import CustomFileUpload from '../../../../components/CustomFileUpload';

const Root_Static_URL = process.env.REACT_APP_STATIC_ROOT_URL;
interface WelcomeDetailFormPropsType {
  welcome?: WelcomeType | null;
  location: { pathname: string; state: any };
}
interface FormValues {
  welcome_id?: number | null;
  title?: string | null;
  subtitle?: string | null;
  description: string | null;
  subdescription: string | null;
  media: string | null;
}

interface MyFormProps {
  welcome?: WelcomeType | null;
  dispatch: AppDispatch;
  location?: { pathname: string; state: any };
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
interface OtherPropsType {
  welcome?: WelcomeType | null;
  location?: { pathname: string; state: any };
  dispatch: AppDispatch;
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
const InnerForm = (props: OtherPropsType & FormikProps<FormValues>) => {
  const {
    // formik props
    values,
    errors,
    dirty,
    // component props
    setFieldValue,
    handleChange,
    handleSubmit,
  } = props;
  const mediaType =
    typeof values.media === 'string' && values.media?.split('.').pop();
  return (
    <form onSubmit={handleSubmit}>
      {/* <>{console.log('valuse', values)}</>
      <>{console.log('error', errors)}</> */}
      <HeaderWrapper>
        <FormTitle id='alert-dialog-title'></FormTitle>
      </HeaderWrapper>
      <FieldsWrapper>
        {props.welcome?.welcome_id && (
          <FieldWrapper width='500px'>
            <TextField
              type='text'
              size='small'
              label='Welcome ID'
              fullWidth
              value={props.welcome?.welcome_id}
              disabled
            />
          </FieldWrapper>
        )}
        <FieldWrapper width='500px'>
          <TextField
            required
            type='text'
            fullWidth
            size='small'
            name='title'
            label='Welcome Title'
            value={values.title}
            onChange={handleChange}
            error={errors?.title ? true : false}
            helperText={errors?.title}
          />
        </FieldWrapper>
        <FieldWrapper width='800px'>
          <TextField
            type='text'
            fullWidth
            multiline
            rows={12}
            size='small'
            name='description'
            label='Description'
            value={values.description}
            onChange={handleChange}
            error={errors?.description ? true : false}
            helperText={errors?.description}
          />
        </FieldWrapper>
        <FieldWrapper width='500px'>
          <TextField
            required
            type='text'
            fullWidth
            size='small'
            name='subtitle'
            label='Why Us'
            value={values.subtitle}
            onChange={handleChange}
            error={errors?.subtitle ? true : false}
            helperText={errors?.subtitle}
          />
        </FieldWrapper>
        <FieldWrapper width='800px'>
          <TextField
            type='text'
            fullWidth
            multiline
            rows={12}
            size='small'
            name='subdescription'
            label='Welcome Description'
            value={values.subdescription}
            onChange={handleChange}
            error={errors?.subdescription ? true : false}
            helperText={errors?.subdescription}
          />
        </FieldWrapper>
        <FieldWrapper width='500px'>
          <CustomFileUpload
            handleUploadFile={(e) => {
              if (e.target.files) {
                const newFile = e.target.files[0];
                if (newFile) {
                  setFieldValue('media', newFile);
                }
              }
            }}
            handleClearFile={() => setFieldValue('media', '')}
            fileName={values.media}
            placehoder='Welcomee Image'
            setFieldValue={setFieldValue}
          >
            Image
          </CustomFileUpload>
          {typeof values.media === 'string' && values.media && (
            <CustomerImgWrapper>
              {mediaType !== 'mp4' ? (
                <ModalImage
                  small={`${Root_Static_URL}/welcome/${values.media}?size=800`}
                  large={`${Root_Static_URL}/welcome/${values.media}?size=800`}
                  alt='Cutomer Space 3'
                />
              ) : (
                <video
                  src={`${Root_Static_URL}/welcome/${values.media}`}
                  autoPlay
                  muted
                  width={'100%'}
                />
              )}
            </CustomerImgWrapper>
          )}
          {errors.media && (
            <div
              style={{
                color: 'red',
                textAlign: 'left',
                marginTop: '8px',
                marginLeft: '5px',
                width: '100%',
                fontSize: '12px',
              }}
            >
              {errors.media}
            </div>
          )}
        </FieldWrapper>
      </FieldsWrapper>
      <ButtonWrapper>
        <CustomButton
          type='submit'
          disabled={Object.keys(errors).length > 0 || !dirty}
        >
          Submit Welcome
        </CustomButton>
      </ButtonWrapper>
    </form>
  );
};
const validFileExtensions = ['webp', 'mp4'];
const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  subtitle: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  subdescription: Yup.string().required('Required'),
  media: Yup.mixed()
    .required('Required')
    .test('is-valid-type', 'Not a valid image type', (value) => {
      let isValid = true;
      if (value instanceof File) {
        const selectedFileType = value.name.split('.').pop();
        isValid = validFileExtensions.includes(selectedFileType as string);
      } else {
        if (typeof value === 'string') {
          const selectedFileType = value?.split('.').pop();
          isValid = validFileExtensions.includes(selectedFileType as string);
        }
      }
      return isValid;
    }),
});
const WelcomeForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: (props) => ({
    title: props.welcome?.title || '',
    subtitle: props.welcome?.subtitle || '',
    media: props.welcome?.media || '',
    description: props.welcome?.description || '',
    subdescription: props.welcome?.subdescription || '',
  }),
  validationSchema,
  enableReinitialize: true,
  async handleSubmit(values: FormValues, { props }) {
    var bodyFormData = new FormData();
    let newValues = { ...values };
    var newFormData = props.transformToFormData(newValues, bodyFormData, null);
    if (props.welcome?.welcome_id) {
      const welcome = {
        id: props.welcome?.welcome_id,
        payload: newFormData,
      };
      props.dispatch(updateWelcome(welcome));
    } else {
      const welcome = newFormData;
      props.dispatch(addWelcome(welcome));
    }
  },
})(InnerForm);
function WelcomeDetailForm({ welcome, location }: WelcomeDetailFormPropsType) {
  const dispatch = useAppDispatch();
  return (
    <Wrapper>
      <WelcomeForm
        transformToFormData={transformToFormData}
        location={location}
        welcome={welcome}
        dispatch={dispatch}
      />
    </Wrapper>
  );
}

export default WelcomeDetailForm;
