import { combineReducers } from '@reduxjs/toolkit';
import requestReducer from '../modules/content/request/requestSlice';
import authReducer from '../modules/auth/authSlice';
import menuReducer from '../modules/menu/menuSlice';
import findUsReducer from '../modules/content/findus/findUsSlice';
import cityReducer from '../modules/content/city/citySlice';
import subcategoryReducer from '../modules/content/subcategory/subcategorySlice';
import jumbotronReducer from '../modules/content/jumbotron/jumbotronSlice';
import galleryReducer from '../modules/content/gallery/gallerySlice';
import approachReducer from '../modules/content/approach/approachSlice';
import reviewReducer from '../modules/content/review/reviewSlice';
import welcomeReducer from '../modules/content/welcome/welcomeSlice';
import adsReducer from '../modules/content/ads/adsSlice';

const rootReducer = combineReducers({
  requestReducer,
  authReducer,
  menuReducer,
  findUsReducer,
  cityReducer,
  subcategoryReducer,
  jumbotronReducer,
  galleryReducer,
  approachReducer,
  reviewReducer,
  welcomeReducer,
  adsReducer,
});

export default rootReducer;
