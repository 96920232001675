import React, { useEffect, useState } from 'react';
import { Wrapper } from './welcomeListContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getAllWelcome } from '../welcomeAsyncActions';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../../components/CustomTable';
import CustomConfirmationModal from '../../../../components/CustomConfirmationModal';
import { deleteWelcome } from '../welcomeAsyncActions';
import { getAllSubcategory } from '../../subcategory/subcategoryAsyncActions';
function WelcomeListContainer() {
  const navigate = useNavigate();
  const columnHeaders = ['ID', 'Title', 'Sub Title', 'Delete'];
  const columnNames = ['welcome_id', 'title', 'subtitle', ''];
  const columnTypes = ['string', 'string', 'string', 'delete'];
  const columnWidth = ['60px', 'auto', 'auto', '60px'];
  const searchFileds = ['title', 'subtitle'];
  const columnHeaderIsSortableList = [true, true, true, false];
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>();
  const { allWelcomes } = useAppSelector((state) => state.welcomeReducer);
  useEffect(() => {
    dispatch(getAllSubcategory());
    dispatch(getAllWelcome());
  }, [dispatch]);
  const handleClickRow = (item: any) => {
    navigate('/welcome-detail', {
      state: {
        id: item?.welcome_id?.toString(),
        pageTitle: 'Edit Welcome',
        referencePath: '/welcome',
        referencePageTitle: 'Welcome List',
      },
    });
  };
  const handleButtonClick = () => {
    navigate('/welcome-detail', {
      state: {
        id: null,
        pageTitle: 'Create Welcome',
        referencePath: '/welcome',
        referencePageTitle: 'Welcome List',
      },
    });
  };
  const handleDeleteItem = (item: any) => {
    setSelectedDeleteId(item.welcome_id);
    setIsOpen(true);
  };
  const handleSubmitDelete = () => {
    if (selectedDeleteId) {
      dispatch(deleteWelcome(selectedDeleteId));
    }
    setIsOpen(false);
  };
  return (
    <Wrapper>
      <CustomTable
        dataList={allWelcomes}
        columnHeaders={columnHeaders}
        columnHeaderIsSortableList={columnHeaderIsSortableList}
        columnNames={columnNames}
        columnWidth={columnWidth}
        columnTypes={columnTypes}
        placeholder='Enter type'
        handleClickRow={handleClickRow}
        handleButtonClick={handleButtonClick}
        buttonText={'Create Welcome'}
        searchFields={searchFileds}
        handleDeleteItem={handleDeleteItem}
      />
      {isOpen && (
        <CustomConfirmationModal
          title={'Confirm Delete Welcome'}
          message={
            'By clicking confiming this welcome will be removed permanently. \nIf you are sure press confirm.'
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleAction={handleSubmitDelete}
        />
      )}
    </Wrapper>
  );
}

export default WelcomeListContainer;
