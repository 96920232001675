import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper } from './welcomeDetailContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getWelcomeById } from '../welcomeAsyncActions';
import WelcomeDetailForm from './WelcomeDetailForm';
import { initiateWelcome } from '../welcomeSlice';
function WelcomeDetailContainer() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { welcome } = useAppSelector((state) => state.welcomeReducer);
  useEffect(() => {
    const id = location.state?.id;

    if (id) {
      dispatch(getWelcomeById(id));
    } else {
      dispatch(initiateWelcome());
    }
  }, [dispatch, location?.state]);

  return (
    <Wrapper>
      {welcome && <WelcomeDetailForm welcome={welcome} location={location} />}
    </Wrapper>
  );
}

export default WelcomeDetailContainer;
