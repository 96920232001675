import { createSlice } from '@reduxjs/toolkit';
import {
  getAllApproaches,
  getApproachById,
  addApproach,
  updateApproach,
  deleteApproach,
} from './approachAsyncActions';
import { history } from '../../../utils/history';
export interface ApproachType {
  approach_id: number | null;
  step: number | null;
  title: string;
  summary: string;
  description: string;
  media: string;
}
export interface ApproachStateType {
  approach: ApproachType | null | undefined;
  allApproaches: Array<ApproachType> | null;
  isGettingApproach: boolean;
}
const initialState: ApproachStateType = {
  approach: null,
  allApproaches: [],
  isGettingApproach: false,
};
export const approachSlice = createSlice({
  name: 'approach',
  initialState,
  reducers: {
    initiateApproach: (state) => {
      state.approach = {
        approach_id: null,
        step: null,
        title: '',
        summary: '',
        description: '',
        media: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllApproaches?.pending, (state) => {
      state.isGettingApproach = true;
    });
    builder.addCase(getAllApproaches?.fulfilled, (state, action: any) => {
      state.isGettingApproach = false;
      state.allApproaches = action?.payload?.data.data;
    });
    builder.addCase(getAllApproaches.rejected, (state, action) => {
      state.isGettingApproach = false;
    });
    // getApproachById
    builder.addCase(getApproachById?.pending, (state) => {
      state.isGettingApproach = true;
    });
    builder.addCase(getApproachById?.fulfilled, (state, action: any) => {
      state.isGettingApproach = false;
      state.approach = action?.payload?.data.data as ApproachType;
    });
    builder.addCase(getApproachById.rejected, (state, action: any) => {
      state.isGettingApproach = false;
    });
    // addApproach
    builder.addCase(addApproach?.pending, (state) => {
      state.isGettingApproach = true;
    });
    builder.addCase(addApproach?.fulfilled, (state, action: any) => {
      state.isGettingApproach = false;
      history.navigate('/approach', {
        state: {
          id: null,
          pageTitle: 'Approach List',
          referencePath: '/approach',
          referencePageTitle: 'Approach List',
        },
      });
    });
    builder.addCase(addApproach.rejected, (state, action: any) => {
      state.isGettingApproach = false;
    });
    // updateApproach
    builder.addCase(updateApproach?.pending, (state) => {
      state.isGettingApproach = true;
    });
    builder.addCase(updateApproach?.fulfilled, (state, action: any) => {
      state.isGettingApproach = false;
      history.navigate('/approach', {
        state: {
          id: null,
          pageTitle: 'Approach List',
          referencePath: '/approach',
          referencePageTitle: 'Approach List',
        },
      });
    });
    builder.addCase(updateApproach.rejected, (state, action: any) => {
      state.isGettingApproach = false;
    });
    // deleteApproach
    builder.addCase(deleteApproach?.pending, (state) => {
      state.isGettingApproach = true;
    });
    builder.addCase(deleteApproach?.fulfilled, (state, action: any) => {
      state.isGettingApproach = false;
      history.navigate('/approach', {
        state: {
          id: null,
          pageTitle: 'Approach List',
          referencePath: '/approach',
          referencePageTitle: 'Approach List',
        },
      });
    });
    builder.addCase(deleteApproach.rejected, (state, action: any) => {
      state.isGettingApproach = false;
    });
  },
});
export const { initiateApproach } = approachSlice.actions;
const approachReducer = approachSlice.reducer;
export default approachReducer;
