import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  min-height: 600px;
`;
export const MenuSectionWrapper = styled.div`
  padding: 10px;
`;
export const SubMenuSectionWrapper = styled.div`
  padding: 10px 10px 0 22px;
`;
export const LinkContainer = styled.div`
  display: grid;
  grid-template-columns: 22px 1fr 18px;
  align-items: center;
  cursor: pointer;
`;
export const LinkWrapper = styled.div`
  display: flex;
  font-weight: 600;
`;
export const SubLinkWrapper = styled.div`
  padding-bottom: 10px;
  text-align: left;
`;
export const CustomLink = styled(Link)`
  text-decoration: none;
  color: #000;
  &:hover {
    color: #e4cd85;
  }
`;
export const CustomIcon = styled(FontAwesomeIcon)``;
