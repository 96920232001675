import React, { useEffect, useState } from 'react';
import { Wrapper } from './findUsListContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getAllFindUs } from '../findUsAsyncActions';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../../components/CustomTable';
import CustomConfirmationModal from '../../../../components/CustomConfirmationModal';
import { deleteFindUs } from '../findUsAsyncActions';

function FindUsListContainer() {
  const navigate = useNavigate();
  const columnHeaders = ['ID', 'Find Us Type', 'Delete'];
  const columnNames = ['find_us_id', 'find_us_type', ''];
  const columnTypes = ['string', 'string', 'delete'];
  const columnWidth = ['10%', '82%', '8%'];
  const searchFileds = ['find_us_type'];
  const columnHeaderIsSortableList = [true, true, false];
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>();
  const { allFindUs } = useAppSelector((state) => state.findUsReducer);
  useEffect(() => {
    dispatch(getAllFindUs());
  }, [dispatch]);
  const handleClickRow = (item: any) => {
    navigate('/findus-detail', {
      state: {
        id: item?.find_us_id?.toString(),
        pageTitle: 'Edit Find Us',
        referencePath: '/findus',
        referencePageTitle: 'Find Us List',
      },
    });
  };
  const handleButtonClick = () => {
    navigate('/findus-detail', {
      state: {
        id: null,
        pageTitle: 'Create Find Us',
        referencePath: '/findus',
        referencePageTitle: 'Find Us List',
      },
    });
  };
  const handleDeleteItem = (item: any) => {
    setSelectedDeleteId(item.find_us_id);
    setIsOpen(true);
  };
  const handleSubmitDelete = () => {
    if (selectedDeleteId) {
      dispatch(deleteFindUs(selectedDeleteId));
    }
    setIsOpen(false);
  };
  return (
    <Wrapper>
      <CustomTable
        dataList={allFindUs}
        columnHeaders={columnHeaders}
        columnHeaderIsSortableList={columnHeaderIsSortableList}
        columnNames={columnNames}
        columnWidth={columnWidth}
        columnTypes={columnTypes}
        placeholder='Enter type'
        handleClickRow={handleClickRow}
        handleButtonClick={handleButtonClick}
        buttonText={'Create Find Us'}
        searchFields={searchFileds}
        handleDeleteItem={handleDeleteItem}
      />
      {isOpen && (
        <CustomConfirmationModal
          title={'Confirm Delete Find Us Option'}
          message={
            'By clicking confiming this find us option will be removed permanently. \nIf you are sure press confirm.'
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleAction={handleSubmitDelete}
        />
      )}
    </Wrapper>
  );
}

export default FindUsListContainer;
