import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getAllRequest,
  getRequestById,
  addRequest,
  updateRequest,
} from './requestAsyncActions';
import { AxiosResponse } from 'axios';
import { AlertColor } from '@mui/material/Alert';
import { history } from '../../../utils/history';
type AllRequestActionType = PayloadAction<
  AxiosResponse<any, any> | undefined,
  string,
  { arg: void; requestId: string; requestStatus: 'fulfilled' },
  never
>;

export interface RequestArrayType {
  request_number: string;
  request_submission_datetime: string;
  is_online_request: boolean;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  phone_number_alt: string;
  visit_schedule_1: string;
  visit_schedule_2: string;
  address_line1: string;
  city: string;
  province: string;
  postal_code: string;
  contract_id: string;
  contract_number: string;
  contract_date: string;
}
export interface RequestType {
  request_id: number | null;
  request_number: string;
  request_submission_datetime: string;
  is_online_request: boolean;
  has_been_seen: boolean | null;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  phone_number_alt: string;
  visit_schedule_1: string | null;
  visit_schedule_2: string | null;
  visit_schedule_3: string | null;
  address_line1: string;
  address_line2: string;
  province: string | null;
  country: string | null;
  postal_code: string | null;
  note: string | null;
  media1: string | null;
  media2: string | null;
  media3: string | null;
  contract_id: number | null;
  city_id?: number | null;
  find_us_id: number | null;
  subcategories: Array<number>;
}
const initialRequest = {
  request_id: null,
  request_number: '',
  request_submission_datetime: '',
  is_online_request: false,
  has_been_seen: true,
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  phone_number_alt: '',
  visit_schedule_1: '',
  visit_schedule_2: '',
  visit_schedule_3: '',
  address_line1: '',
  address_line2: '',
  province: 'Ontario',
  country: '',
  postal_code: '',
  note: '',
  media1: '',
  media2: '',
  media3: '',
  contract_id: null,
  city_id: null,
  find_us_id: null,
  subcategories: [],
};
export interface requestStateType {
  request: RequestType | null | undefined;
  allRequests: Array<RequestArrayType> | null;
  isGettingReqsuet: boolean;
  isAlertDispalyed?: boolean;
  alertType: AlertColor;
  alertMessage: string;
}
const initialState: requestStateType = {
  request: null,
  allRequests: [],
  isGettingReqsuet: false,
  isAlertDispalyed: false,
  alertType: 'success',
  alertMessage: '',
};
export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    initiateRequest: (state) => {
      state.request = initialRequest;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRequest?.pending, (state) => {
      state.isGettingReqsuet = true;
    });
    builder.addCase(
      getAllRequest?.fulfilled,
      (state, action: AllRequestActionType) => {
        state.isGettingReqsuet = false;
        state.allRequests = action?.payload?.data.data;
      }
    );
    builder.addCase(getAllRequest.rejected, (state, action) => {
      state.isGettingReqsuet = false;
    });
    builder.addCase(getRequestById?.pending, (state) => {
      state.isGettingReqsuet = true;
    });
    builder.addCase(getRequestById?.fulfilled, (state, action: any) => {
      state.isGettingReqsuet = false;
      state.request = action?.payload?.data.data as RequestType;
    });
    builder.addCase(getRequestById.rejected, (state, action: any) => {
      state.isGettingReqsuet = false;
      //
      state.alertType = 'error';
      state.isAlertDispalyed = true;
      state.alertMessage = `${action?.payload?.message}`;
    });
    // addRequest
    builder.addCase(addRequest?.pending, (state) => {
      state.isGettingReqsuet = true;
    });
    builder.addCase(addRequest?.fulfilled, (state, action: any) => {
      state.isGettingReqsuet = false;
      history.navigate('/request', {
        state: {
          id: null,
          pageTitle: 'Request List',
          referencePath: '/request',
          referencePageTitle: 'Request List',
        },
      });
    });
    builder.addCase(addRequest.rejected, (state, action: any) => {
      state.isGettingReqsuet = false;
    });
    // updateRequest
    builder.addCase(updateRequest?.pending, (state) => {
      state.isGettingReqsuet = true;
    });
    builder.addCase(updateRequest?.fulfilled, (state, action: any) => {
      state.isGettingReqsuet = false;
      history.navigate('/request', {
        state: {
          id: null,
          pageTitle: 'Request List',
          referencePath: '/request',
          referencePageTitle: 'Request List',
        },
      });
    });
    builder.addCase(updateRequest.rejected, (state, action: any) => {
      state.isGettingReqsuet = false;
    });
  },
});
export const { initiateRequest } = requestSlice.actions;
const requestReducer = requestSlice.reducer;
export default requestReducer;
