import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { displayAlert } from '../../auth/authSlice';
const root_url = process.env.REACT_APP_ROOT_URL;

export const getCityById = createAsyncThunk(
  'city/getCityById',
  async (id: number, thunkApi) => {
    try {
      const res = await api.get(`${root_url}/city/find/${id}`);
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const getAllCity = createAsyncThunk(
  'city/getAllCity',
  async (_, thunkApi) => {
    try {
      const res = await api.get(`${root_url}/city/find`);
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const addCity = createAsyncThunk(
  'city/addCity',
  async (payload: FormData, thunkApi) => {
    try {
      const res = await api.post(`${root_url}/city/add`, payload);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: `City was successfully added.`,
        })
      );
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const updateCity = createAsyncThunk(
  'city/updateCity',
  async ({ id, payload }: any, thunkApi) => {
    try {
      const res = await api.put(`${root_url}/city/update/${id}`, payload);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: `City was successfully updated.`,
        })
      );
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const deleteCity = createAsyncThunk(
  'city/deleteCity',
  async (id: number, thunkApi) => {
    try {
      const res = await api.delete(`${root_url}/city/delete/${id}`);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: res.data.data,
        })
      );
      thunkApi.dispatch(getAllCity());
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
