import { Fragment } from 'react';
import { Wrapper, ContentWrapper } from './contentContainerStyles';
import { Routes, Route } from 'react-router-dom';
import PageHeader from '../pageHeader/PageHeader';
import RequestListContainer from './request/requestList/RequestListContainer';
import RequestDetailContainer from './request/requestDetail/RequestDetailContainer';
import FindUsListContainer from './findus/findusList/FindUsListContainer';
import FindUsDetailContainer from './findus/findusDetail/FindUsDetailContainer';
import CityListContainer from './city/cityList/CityListContainer';
import CityDetailContainer from './city/cityDetail/CityDetailContainer';
import SubcategoryListContainer from './subcategory/cityList/SubcategoryListContainer';
import SubcategoryDetailContainer from './subcategory/subcategoryDetail/SubcategoryDetailContainer';
import JumbotronListContainer from './jumbotron/jumbotronList/JumbotronListContainer';
import JumbotronDetailContainer from './jumbotron/jumbotronDetail/JumbotronDetailContainer';
import GalleryDetailContainer from './gallery/galleryDetail/GalleryDetailContainer';
import GalleryListContainer from './gallery/galleryList/GalleryListContainer';
import ApproachDetailContainer from './approach/approachDetail/ApproachDetailContainer';
import ApproachListContainer from './approach/approachList/ApproachListContainer';
import ReviewDetailContainer from './review/reviewDetail/ReviewDetailContainer';
import ReviewListContainer from './review/reviewList/ReviewListContainer';
import WelcomeListContainer from './welcome/welcomeList/WelcomeListContainer';
import WelcomeDetailContainer from './welcome/welcomeDetail/WelcomeDetailContainer';
import ADSListContainer from './ads/adsList/ADSListContainer';
import ADSDetailContainer from './ads/adsDetail/ADSDetailContainer';

function ContentContainer() {
  return (
    <Wrapper>
      <PageHeader />
      <ContentWrapper>
        <Routes>
          <Route
            path='/'
            element={
              <Fragment>
                <RequestListContainer />
              </Fragment>
            }
          />
          <Route
            path='subcategory-detail'
            element={
              <Fragment>
                <SubcategoryDetailContainer />
              </Fragment>
            }
          />
          <Route
            path='subcategory'
            element={
              <Fragment>
                <SubcategoryListContainer />
              </Fragment>
            }
          />
          <Route
            path='jumbotron-detail'
            element={
              <Fragment>
                <JumbotronDetailContainer />
              </Fragment>
            }
          />
          <Route
            path='jumbotron'
            element={
              <Fragment>
                <JumbotronListContainer />
              </Fragment>
            }
          />
          <Route
            path='gallery-detail'
            element={
              <Fragment>
                <GalleryDetailContainer />
              </Fragment>
            }
          />
          <Route
            path='gallery'
            element={
              <Fragment>
                <GalleryListContainer />
              </Fragment>
            }
          />
          <Route
            path='approach-detail'
            element={
              <Fragment>
                <ApproachDetailContainer />
              </Fragment>
            }
          />
          <Route
            path='approach'
            element={
              <Fragment>
                <ApproachListContainer />
              </Fragment>
            }
          />
          <Route
            path='review-detail'
            element={
              <Fragment>
                <ReviewDetailContainer />
              </Fragment>
            }
          />
          <Route
            path='review'
            element={
              <Fragment>
                <ReviewListContainer />
              </Fragment>
            }
          />
          <Route
            path='welcome-detail'
            element={
              <Fragment>
                <WelcomeDetailContainer />
              </Fragment>
            }
          />
          <Route
            path='welcome'
            element={
              <Fragment>
                <WelcomeListContainer />
              </Fragment>
            }
          />
          <Route
            path='ads-detail'
            element={
              <Fragment>
                <ADSDetailContainer />
              </Fragment>
            }
          />
          <Route
            path='ads'
            element={
              <Fragment>
                <ADSListContainer />
              </Fragment>
            }
          />

          <Route
            path='request-detail'
            element={
              <Fragment>
                <RequestDetailContainer />
              </Fragment>
            }
          />
          <Route
            path='request'
            element={
              <Fragment>
                <RequestListContainer />
              </Fragment>
            }
          />
          <Route
            path='findus-detail'
            element={
              <Fragment>
                <FindUsDetailContainer />
              </Fragment>
            }
          />
          <Route
            path='findus'
            element={
              <Fragment>
                <FindUsListContainer />
              </Fragment>
            }
          />
          <Route
            path='city-detail'
            element={
              <Fragment>
                <CityDetailContainer />
              </Fragment>
            }
          />
          <Route
            path='city'
            element={
              <Fragment>
                <CityListContainer />
              </Fragment>
            }
          />
        </Routes>
      </ContentWrapper>
    </Wrapper>
  );
}

export default ContentContainer;
