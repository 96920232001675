import { useEffect, useState } from 'react';
import { Wrapper } from './requestListContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getAllRequest } from '../requestAsyncActions';
// import { getAllCity } from '../../city/cityAsyncActions';
// import { getAllFindUs } from '../../findus/findUsAsyncActions';
// import { getAllSubcategory } from '../../subcategory/subcategoryAsyncActions';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomTable from '../../../../components/CustomTable';
// import CustomConfirmationModal from '../../../../components/CustomConfirmationModal';

function RequestListContainer() {
  const navigate = useNavigate();
  const columnHeaders = [
    'ID',
    'Request date',
    'First Name',
    'Last Name',
    'Email',
    'Phone No.',
    'City',
    'Contract No.',
  ];
  const columnNames = [
    'request_id',
    'request_submission_datetime',
    'first_name',
    'last_name',
    'email',
    'phone_number',
    'city',
    'contract_number',
  ];
  const columnTypes = [
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
  ];
  const columnWidth = [
    '50px',
    '180px',
    '100px',
    '110px',
    '200px',
    '120px',
    '90px',
    '110px',
  ];
  const searchFileds = ['first_name', 'last_name', 'phone_number'];
  const columnHeaderIsSortableList = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ];
  const dispatch = useAppDispatch();
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  const { allRequests } = useAppSelector((state) => state.requestReducer);
  // const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>();
  useEffect(() => {
    dispatch(getAllRequest());
  }, [dispatch]);
  const handleClickRow = (item: any) => {
    navigate('/request-detail', {
      state: {
        id: item?.request_id?.toString(),
        pageTitle: 'Edit Request',
        referencePath: '/request',
        referencePageTitle: 'Request List',
      },
    });
  };
  const handleButtonClick = () => {
    navigate('/request-detail', {
      state: {
        id: null,
        pageTitle: 'Create Request',
        referencePath: '/request',
        referencePageTitle: 'Request List',
      },
    });
  };
  // const handleDeleteItem = (item: any) => {
  // setSelectedDeleteId(item.city_id);
  // setIsOpen(true);
  // };
  // const handleSubmitDelete = () => {
  //   if (selectedDeleteId) {
  //     dispatch(deleteRequest(selectedDeleteId));
  //   }
  //   setIsOpen(false);
  // };
  return (
    <Wrapper>
      <CustomTable
        dataList={allRequests}
        columnHeaders={columnHeaders}
        columnHeaderIsSortableList={columnHeaderIsSortableList}
        columnNames={columnNames}
        columnWidth={columnWidth}
        columnTypes={columnTypes}
        placeholder='Enter phone, customer name or email'
        handleClickRow={handleClickRow}
        // setExportingData={() => setExportingData(allRequests)}
        handleButtonClick={handleButtonClick}
        buttonText={'Create Request'}
        searchFields={searchFileds}
        // handleDeleteItem={handleDeleteItem}
      />
    </Wrapper>
  );
}

export default RequestListContainer;
