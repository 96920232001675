import { createSlice } from '@reduxjs/toolkit';
import {
  getAllJumbotron,
  getJumbotronById,
  addJumbotron,
  updateJumbotron,
  deleteJumbotron,
} from './jumbotronAsyncActions';
import { history } from '../../../utils/history';
export interface JumbotronType {
  jumbotron_id: number | null;
  title: string;
  subtitle: string;
  media: string;
  is_main_jumbotron: boolean;
  subcategory_id: number | null;
  subcategory_name?: number | null;
}
export interface JumbotronStateType {
  jumbotron: JumbotronType | null | undefined;
  allJumbotrons: Array<JumbotronType> | null;
  isGettingJumbotron: boolean;
}
const initialState: JumbotronStateType = {
  jumbotron: null,
  allJumbotrons: [],
  isGettingJumbotron: false,
};
export const jumbotronSlice = createSlice({
  name: 'jumbotron',
  initialState,
  reducers: {
    initiateJumbotron: (state) => {
      state.jumbotron = {
        jumbotron_id: null,
        title: '',
        subtitle: '',
        media: '',
        is_main_jumbotron: false,
        subcategory_id: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllJumbotron?.pending, (state) => {
      state.isGettingJumbotron = true;
    });
    builder.addCase(getAllJumbotron?.fulfilled, (state, action: any) => {
      state.isGettingJumbotron = false;
      state.allJumbotrons = action?.payload?.data.data;
    });
    builder.addCase(getAllJumbotron.rejected, (state, action) => {
      state.isGettingJumbotron = false;
    });
    // getJumbotronById
    builder.addCase(getJumbotronById?.pending, (state) => {
      state.isGettingJumbotron = true;
    });
    builder.addCase(getJumbotronById?.fulfilled, (state, action: any) => {
      state.isGettingJumbotron = false;
      state.jumbotron = action?.payload?.data.data as JumbotronType;
    });
    builder.addCase(getJumbotronById.rejected, (state, action: any) => {
      state.isGettingJumbotron = false;
    });
    // addJumbotron
    builder.addCase(addJumbotron?.pending, (state) => {
      state.isGettingJumbotron = true;
    });
    builder.addCase(addJumbotron?.fulfilled, (state, action: any) => {
      state.isGettingJumbotron = false;
      history.navigate('/jumbotron', {
        state: {
          id: null,
          pageTitle: 'Jumbotron List',
          referencePath: '/jumbotron',
          referencePageTitle: 'Jumbotron List',
        },
      });
    });
    builder.addCase(addJumbotron.rejected, (state, action: any) => {
      state.isGettingJumbotron = false;
    });
    // updateJumbotron
    builder.addCase(updateJumbotron?.pending, (state) => {
      state.isGettingJumbotron = true;
    });
    builder.addCase(updateJumbotron?.fulfilled, (state, action: any) => {
      state.isGettingJumbotron = false;
      history.navigate('/jumbotron', {
        state: {
          id: null,
          pageTitle: 'Jumbotron List',
          referencePath: '/jumbotron',
          referencePageTitle: 'Jumbotron List',
        },
      });
    });
    builder.addCase(updateJumbotron.rejected, (state, action: any) => {
      state.isGettingJumbotron = false;
    });
    // deleteJumbotron
    builder.addCase(deleteJumbotron?.pending, (state) => {
      state.isGettingJumbotron = true;
    });
    builder.addCase(deleteJumbotron?.fulfilled, (state, action: any) => {
      state.isGettingJumbotron = false;
      history.navigate('/jumbotron', {
        state: {
          id: null,
          pageTitle: 'Jumbotron List',
          referencePath: '/jumbotron',
          referencePageTitle: 'Jumbotron List',
        },
      });
    });
    builder.addCase(deleteJumbotron.rejected, (state, action: any) => {
      state.isGettingJumbotron = false;
    });
  },
});
export const { initiateJumbotron } = jumbotronSlice.actions;
const jumbotronReducer = jumbotronSlice.reducer;
export default jumbotronReducer;
