import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper } from './requestDetailContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getRequestById } from '../requestAsyncActions';
import RequestDetailForm from './RequestDetailForm';
import { getAllCity } from '../../city/cityAsyncActions';
import { getAllFindUs } from '../../findus/findUsAsyncActions';
import { getAllSubcategory } from '../../subcategory/subcategoryAsyncActions';
import { initiateRequest } from '../requestSlice';
function RequestDetailContainer() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { request } = useAppSelector((state) => state.requestReducer);
  const { allFindUs } = useAppSelector((state) => state.findUsReducer);
  const { allCity } = useAppSelector((state) => state.cityReducer);
  const { allSubcategory } = useAppSelector(
    (state) => state.subcategoryReducer
  );
  useEffect(() => {
    const id = location.state?.id;
    console.log('id=>', id);
    if (id) {
      dispatch(getRequestById(id));
    } else {
      dispatch(initiateRequest());
    }
    dispatch(getAllCity());
    dispatch(getAllFindUs());
    dispatch(getAllSubcategory());
  }, [dispatch, location?.state]);
  console.log('request[Detail]=>', request);
  return (
    <Wrapper>
      <RequestDetailForm
        request={request}
        location={location}
        allFindUs={allFindUs}
        allCity={allCity}
        allSubcategory={allSubcategory}
      />
    </Wrapper>
  );
}

export default RequestDetailContainer;
