import React, { useEffect, useState } from 'react';
import { Wrapper } from './adsListContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getAllADS } from '../adsAsyncActions';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../../components/CustomTable';
import CustomConfirmationModal from '../../../../components/CustomConfirmationModal';
import { deleteADS } from '../adsAsyncActions';

function ADSListContainer() {
  const navigate = useNavigate();
  const columnHeaders = [
    'ID',
    'Des 1',
    'Des 2',
    'Des 3',
    'Des 4',
    'Des 5',
    'Delete',
  ];
  const columnNames = ['ads_id', 'des1', 'des2', 'des3', 'des4', 'des5', ''];
  const columnTypes = [
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'delete',
  ];
  const columnWidth = ['10%', 'auto', 'auto', 'auto', 'auto', 'auto', '8%'];
  const searchFileds = ['des1', 'des2', 'des3', 'des4', 'des5'];
  const columnHeaderIsSortableList = [
    true,
    true,
    true,
    true,
    true,
    true,
    false,
  ];
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>();
  const { allADS } = useAppSelector((state) => state.adsReducer);
  useEffect(() => {
    dispatch(getAllADS());
  }, [dispatch]);
  const handleClickRow = (item: any) => {
    navigate('/ads-detail', {
      state: {
        id: item?.ads_id?.toString(),
        pageTitle: 'Edit ADS',
        referencePath: '/ads',
        referencePageTitle: 'ADS List',
      },
    });
  };
  const handleButtonClick = () => {
    navigate('/ads-detail', {
      state: {
        id: null,
        pageTitle: 'Create ADS',
        referencePath: '/ads',
        referencePageTitle: 'ADS List',
      },
    });
  };
  const handleDeleteItem = (item: any) => {
    setSelectedDeleteId(item.ads_id);
    setIsOpen(true);
  };
  const handleSubmitDelete = () => {
    if (selectedDeleteId) {
      dispatch(deleteADS(selectedDeleteId));
    }
    setIsOpen(false);
  };
  return (
    <Wrapper>
      <CustomTable
        dataList={allADS}
        columnHeaders={columnHeaders}
        columnHeaderIsSortableList={columnHeaderIsSortableList}
        columnNames={columnNames}
        columnWidth={columnWidth}
        columnTypes={columnTypes}
        placeholder='Enter type'
        handleClickRow={handleClickRow}
        handleButtonClick={handleButtonClick}
        buttonText={'Create ADS'}
        searchFields={searchFileds}
        handleDeleteItem={handleDeleteItem}
      />
      {isOpen && (
        <CustomConfirmationModal
          title={'Confirm Delete ADS'}
          message={
            'By clicking confiming this ads will be removed permanently. \nIf you are sure press confirm.'
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleAction={handleSubmitDelete}
        />
      )}
    </Wrapper>
  );
}

export default ADSListContainer;
