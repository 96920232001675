import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper } from './galleryDetailContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getGalleryById } from '../galleryAsyncActions';
import GalleryDetailForm from './GalleryDetailForm';
import { initiateGallery } from '../gallerySlice';
function GalleryDetailContainer() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { gallery } = useAppSelector((state) => state.galleryReducer);
  const { allSubcategory } = useAppSelector(
    (state) => state.subcategoryReducer
  );
  useEffect(() => {
    const id = location.state?.id;

    if (id) {
      dispatch(getGalleryById(id));
    } else {
      dispatch(initiateGallery());
    }
  }, [dispatch, location?.state]);

  return (
    <Wrapper>
      {gallery && (
        <GalleryDetailForm
          gallery={gallery}
          location={location}
          allSubcategory={allSubcategory}
        />
      )}
    </Wrapper>
  );
}

export default GalleryDetailContainer;
