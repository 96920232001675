import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { displayAlert } from '../../auth/authSlice';
const root_url = process.env.REACT_APP_ROOT_URL;

export const getJumbotronById = createAsyncThunk(
  'jumbotron/getJumbotronById',
  async (id: number, thunkApi) => {
    try {
      const res = await api.get(`${root_url}/jumbo/find/${id}`);
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const getAllJumbotron = createAsyncThunk(
  'jumbotron/getAllJumbotron',
  async (_, thunkApi) => {
    try {
      const res = await api.get(`${root_url}/jumbo/find`);
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const addJumbotron = createAsyncThunk(
  'jumbotron/addJumbotron',
  async (payload: FormData, thunkApi) => {
    try {
      const res = await api.post(`${root_url}/jumbo/add`, payload);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: `Jumbotron was successfully added.`,
        })
      );
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const updateJumbotron = createAsyncThunk(
  'jumbotron/updateJumbotron',
  async ({ id, payload }: any, thunkApi) => {
    try {
      const res = await api.put(`${root_url}/jumbo/update/${id}`, payload);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: `Jumbotron was successfully updated.`,
        })
      );
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const deleteJumbotron = createAsyncThunk(
  'jumbotron/deleteJumbotron',
  async (id: number, thunkApi) => {
    try {
      const res = await api.delete(`${root_url}/jumbo/delete/${id}`);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: res.data.data,
        })
      );
      thunkApi.dispatch(getAllJumbotron());
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
