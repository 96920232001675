import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper } from './findUsDetailContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getFindUsById } from '../findUsAsyncActions';
import FindUsDetailForm from './FindUsDetailForm';
import { initiateFindUs } from '../findUsSlice';
function FindUsDetailContainer() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { findUs } = useAppSelector((state) => state.findUsReducer);
  useEffect(() => {
    const id = location.state?.id;
    if (id) {
      dispatch(getFindUsById(id));
    } else {
      dispatch(initiateFindUs());
    }
  }, [dispatch, location?.state]);
  return (
    <Wrapper>
      <FindUsDetailForm findUs={findUs} location={location} />
    </Wrapper>
  );
}

export default FindUsDetailContainer;
