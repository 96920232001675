import React, { useState, useEffect } from 'react';
import './App.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './modules/header/Header';
import ContentContainer from './modules/content/ContentContainer';
import MenuContainer from './modules/menu/MenuContainer';
import LoginContainer from './modules/auth/LoginContainer';
import { useAppSelector, useAppDispatch } from './store/reduxHooks';
import Snackbar from '@mui/material/Snackbar';
import { closeAlert } from './modules/auth/authSlice';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { history } from './utils/history';
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  const dispatch = useAppDispatch();
  const storedToken = localStorage.getItem('token');
  const { token, alertType, isAlertDispalyed, alertMessage } = useAppSelector(
    (state) => state.authReducer
  );
  const [currentToken, setCurrentToken] = useState<string | null>(null);
  useEffect(() => {
    if (token || storedToken) {
      setCurrentToken(token || storedToken);
    } else {
      setCurrentToken(null);
    }
  }, [token, storedToken]);
  return (
    <>
      {currentToken ? (
        <div className='app'>
          <div className='header'>
            <Header />
          </div>
          <main className='main'>
            <section className='sidebar'>
              <MenuContainer />
            </section>
            <section className='content-container'>
              <div className='content'>
                <ContentContainer />
              </div>
              <footer className='footer'></footer>
            </section>
          </main>
        </div>
      ) : (
        <section className='login-wrapper'>
          <LoginContainer />
        </section>
      )}
      <Snackbar
        open={isAlertDispalyed}
        autoHideDuration={20000}
        onClose={() => dispatch(closeAlert())}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => dispatch(closeAlert())}
          severity={alertType}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default App;
