import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper } from './reviewDetailContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getReviewById } from '../reviewAsyncActions';
import ReviewDetailForm from './ReviewDetailForm';
import { initiateReview } from '../reviewSlice';
function ReviewDetailContainer() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { review } = useAppSelector((state) => state.reviewReducer);
  useEffect(() => {
    const id = location.state?.id;

    if (id) {
      dispatch(getReviewById(id));
    } else {
      dispatch(initiateReview());
    }
  }, [dispatch, location?.state]);

  return (
    <Wrapper>
      {review && <ReviewDetailForm review={review} location={location} />}
    </Wrapper>
  );
}

export default ReviewDetailContainer;
