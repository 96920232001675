import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
type FieldsWrapperType = {
  grid?: string;
  width?: string;
};
export const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  min-height: 700px;
`;
export const FormTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding: 15px 0;
`;
export const FieldsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;
export const FieldsWrapper = styled.div<FieldsWrapperType>`
  display: ${(props) => (props.grid ? 'grid' : 'flex')};
  grid-template-columns: ${(props) => props.grid || 'block'};
  grid-gap: 10px;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  width: 100%;
`;
export const FieldWrapper = styled.div<FieldsWrapperType>`
  margin-bottom: 20px;
  display: ${(props) => (props.grid ? 'grid' : 'block')};
  grid-template-columns: ${(props) => props.grid || 'none'};
  grid-gap: 5px;
  max-width: ${(props) => props.width || '100%'};
`;
export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
`;
export const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  cursor: pointer;
`;
export const Note = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  color: orange;
`;
export const DataTimeWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;
export const UploadImageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
`;
export const CustomerImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-radius: 10px;
  border: 8px solid black;
  &:hover {
    background-color: gray;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
`;
export const CustomerImg = styled.img`
  width: 200px;
`;
