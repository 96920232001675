import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const Wrapper = styled.div`
  margin: 20px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const PageTitle = styled.div`
  font-size: 25px;
  font-weight: 700px;
  cursor: pointer;
`;
export const CustomIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-right: 10px;
  font-size: 22px;
`;
