import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertColor } from '@mui/material/Alert';
import { faList, faCartShopping } from '@fortawesome/free-solid-svg-icons';
type MenuItemType = {
  id: number;
  title: string;
  path: string;
  icon: any;
  isOpen: boolean;
  subMenu: Array<{
    id: number;
    title: string;
    path: string;
    state: { id?: null | string; pageTitle: string };
    isSelected: boolean;
  }>;
};

type MenuType = Array<MenuItemType>;

const initialMenu = [
  {
    id: 0,
    title: 'Content Management',
    path: '/',
    icon: faList,
    isOpen: false,
    subMenu: [
      {
        id: 0,
        title: 'Subcategory',
        path: '/subcategory',
        state: { id: null, pageTitle: 'Subcategory List' },
        isSelected: false,
      },
      {
        id: 1,
        title: 'Jumbo',
        path: '/jumbotron',
        state: { id: null, pageTitle: 'Jumbotron List' },
        isSelected: false,
      },
      {
        id: 2,
        title: 'gallery',
        path: '/gallery',
        state: { id: null, pageTitle: 'Gallery List' },
        isSelected: false,
      },
      {
        id: 3,
        title: 'approach',
        path: '/approach',
        state: { id: null, pageTitle: 'Approach List' },
        isSelected: false,
      },
      {
        id: 4,
        title: 'Review',
        path: '/review',
        state: { id: null, pageTitle: 'Review List' },
        isSelected: false,
      },
      {
        id: 5,
        title: 'Welcome',
        path: '/welcome',
        state: { id: null, pageTitle: 'Welcome List' },
        isSelected: false,
      },
      {
        id: 6,
        title: 'Advertise',
        path: '/ads',
        state: { id: null, pageTitle: 'Advertise List' },
        isSelected: false,
      },
    ],
  },
  {
    id: 1,
    title: 'Request Management',
    path: '/',
    icon: faCartShopping,
    isOpen: false,
    subMenu: [
      {
        id: 0,
        title: 'Find Us',
        path: '/findus',
        state: { id: null, pageTitle: 'Find Us List' },
        isSelected: false,
      },
      {
        id: 1,
        title: 'Cities',
        path: '/city',
        state: { id: null, pageTitle: 'Cities List' },
        isSelected: false,
      },
      {
        id: 2,
        title: 'Requests',
        path: '/request',
        state: { id: null, pageTitle: 'Request List' },
        isSelected: false,
      },
      {
        id: 3,
        title: 'Contracts',
        path: '/contract',
        state: { id: null, pageTitle: 'Contract List' },
        isSelected: false,
      },
    ],
  },
];
export interface MenuStateType {
  menu: MenuType;
  isAlertDispalyed?: boolean;
  alertType: AlertColor;
  alertMessage: string;
}
const initialState: MenuStateType = {
  menu: initialMenu,
  isAlertDispalyed: false,
  alertType: 'success',
  alertMessage: '',
};
export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    updateMenu: (state, action: PayloadAction<MenuType>) => {
      state.menu = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(getAllRequest?.pending, (state) => {
    //   state.isGettingReqsuet = true;
    // });
    // builder.addCase(getAllRequest?.fulfilled, (state, action: ActionType) => {
    //   state.isGettingReqsuet = false;
    //   state.allRequests = action?.payload?.data.data;
    // });
    // builder.addCase(getAllRequest.rejected, (state, action) => {
    //   state.isGettingReqsuet = false;
    //   //   state.users = [];
    //   //   state.error = action.error.message;
    // });
  },
});
export const { updateMenu } = menuSlice.actions;
const menuReducer = menuSlice.reducer;
export default menuReducer;
