import { createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
const root_url = process.env.REACT_APP_ROOT_URL;
interface LoginObjType {
  username: string;
  password: string;
}
type LoginFunc = AsyncThunk<AxiosResponse<any, any>, LoginObjType, any>;
export const login: LoginFunc = createAsyncThunk(
  'auth/login',
  async (loginData: LoginObjType, thunkApi) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        origin: process.env.REACT_APP_ROOT_URL,
      };
      const res = await axios.post(
        `${root_url}/auth/login`,
        { ...loginData },
        { headers }
      );
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Network Error (Cors Origin)',
        });
      } else if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Unkown Reason ',
        });
      }
    }
  }
);
