import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { displayAlert } from '../../auth/authSlice';
const root_url = process.env.REACT_APP_ROOT_URL;

export const getWelcomeById = createAsyncThunk(
  'welcome/getWelcomeById',
  async (id: number, thunkApi) => {
    try {
      const res = await api.get(`${root_url}/welcome/find/${id}`);
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const getAllWelcome = createAsyncThunk(
  'welcome/getAllWelcome',
  async (_, thunkApi) => {
    try {
      const res = await api.get(`${root_url}/welcome/find`);
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const addWelcome = createAsyncThunk(
  'welcome/addWelcome',
  async (payload: FormData, thunkApi) => {
    try {
      const res = await api.post(`${root_url}/welcome/add`, payload);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: `Welcome was successfully added.`,
        })
      );
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const updateWelcome = createAsyncThunk(
  'welcome/updateWelcome',
  async ({ id, payload }: any, thunkApi) => {
    try {
      const res = await api.put(`${root_url}/welcome/update/${id}`, payload);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: `Welcome was successfully updated.`,
        })
      );
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const deleteWelcome = createAsyncThunk(
  'welcome/deleteWelcome',
  async (id: number, thunkApi) => {
    try {
      const res = await api.delete(`${root_url}/welcome/delete/${id}`);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: res.data.data,
        })
      );
      thunkApi.dispatch(getAllWelcome());
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
