import { logout } from '../auth/authSlice';
import { useAppDispatch } from '../../store/reduxHooks';
import { useNavigate } from 'react-router-dom';
import {
  Wrapper,
  Logo,
  LogoWrapper,
  SignoutWrapper,
  SignoutButton,
} from './headerStyles';
function Header() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo
          src='images/horizontal-fnal-logo.webp'
          alt='a golden closet picture on the left with the company name and url'
        />
      </LogoWrapper>
      <div></div>
      <SignoutWrapper>
        <SignoutButton onClick={handleLogout}>Logout</SignoutButton>
      </SignoutWrapper>
    </Wrapper>
  );
}

export default Header;
