import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { displayAlert } from '../../auth/authSlice';
const root_url = process.env.REACT_APP_ROOT_URL;

export const getApproachById = createAsyncThunk(
  'approach/getApproachById',
  async (id: number, thunkApi) => {
    try {
      const res = await api.get(`${root_url}/approach/find/${id}`);
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const getAllApproaches = createAsyncThunk(
  'approach/getAllApproaches',
  async (_, thunkApi) => {
    try {
      const res = await api.get(`${root_url}/approach/find`);
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const addApproach = createAsyncThunk(
  'approach/addApproach',
  async (payload: FormData, thunkApi) => {
    try {
      const res = await api.post(`${root_url}/approach/add`, payload);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: `Approach was successfully added.`,
        })
      );
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const updateApproach = createAsyncThunk(
  'approach/updateApproach',
  async ({ id, payload }: any, thunkApi) => {
    try {
      const res = await api.put(`${root_url}/approach/update/${id}`, payload);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: `Approach was successfully updated.`,
        })
      );
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const deleteApproach = createAsyncThunk(
  'approach/deleteApproach',
  async (id: number, thunkApi) => {
    try {
      const res = await api.delete(`${root_url}/approach/delete/${id}`);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: res.data.data,
        })
      );
      thunkApi.dispatch(getAllApproaches());
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
