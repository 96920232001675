import React, { useEffect, useState } from 'react';
import { Wrapper, PageTitle, CustomIcon } from './pageHeaderStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
function PageHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useState<string>('Home');
  // const [urlID, setUrlID] = useState<string>();
  useEffect(() => {
    const newPageTitle = location.state?.pageTitle;
    setPageTitle(newPageTitle);
  }, [location?.pathname, location?.state]);
  const handleBackToList: () => void = () => {
    navigate(location.state?.referencePath, {
      state: {
        id: null,
        pageTitle: location.state?.referencePageTitle,
        referencePath: location.state?.referencePath,
        referencePageTitle: location.state?.referencePageTitle,
      },
    });
  };
  return (
    <Wrapper>
      <PageTitle onClick={handleBackToList}>
        {(pageTitle?.toLowerCase().startsWith('edit') ||
          pageTitle?.toLowerCase().startsWith('create')) && (
          <CustomIcon icon={faChevronLeft} />
        )}
        {pageTitle}
      </PageTitle>
    </Wrapper>
  );
}

export default PageHeader;
