import CustomButton from '../../../../components/CustomButton';
import transformToFormData from '../../../../utils/transformToFormData';
import { addFindUs, updateFindUs } from '../findUsAsyncActions';
import { TextField } from '@mui/material';
import { withFormik, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import {
  Wrapper,
  FormTitle,
  FieldsWrapper,
  FieldWrapper,
  HeaderWrapper,
  ButtonWrapper,
} from './findUsDetailFormStyles';
import { FindUsType } from '../findUsSlice';
import { AppDispatch } from '../../../../store/store';
import { useAppDispatch } from '../../../../store/reduxHooks';
interface FindUsDetailFormPropsType {
  findUs?: FindUsType | null;
  location: { pathname: string; state: any };
}
interface FormValues {
  find_us_id?: number | null;
  find_us_type?: string | null;
}

interface MyFormProps {
  findUs?: FindUsType | null;
  dispatch: AppDispatch;
  location?: { pathname: string; state: any };
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
interface OtherPropsType {
  findUs?: FindUsType | null;
  location?: { pathname: string; state: any };
  dispatch: AppDispatch;
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
const InnerForm = (props: OtherPropsType & FormikProps<FormValues>) => {
  const {
    // formik props
    values,
    errors,
    dirty,
    // component props
    handleChange,
    handleSubmit,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      {/* <>{console.log('valuse', values)}</> */}
      <HeaderWrapper>
        <FormTitle id='alert-dialog-title'></FormTitle>
      </HeaderWrapper>
      <FieldsWrapper>
        {props.findUs?.find_us_id && (
          <FieldWrapper width='300px'>
            <TextField
              fullWidth
              type='text'
              size='small'
              label='Find Us ID'
              value={props.findUs?.find_us_id}
              disabled
            />
          </FieldWrapper>
        )}
        <FieldWrapper width='300px'>
          <TextField
            required
            type='text'
            fullWidth
            size='small'
            name='find_us_type'
            label='Find Us Option'
            value={values.find_us_type}
            onChange={handleChange}
            error={errors?.find_us_type ? true : false}
            helperText={errors?.find_us_type}
          />
          <ErrorMessage name='find_us_type' component='div' />
        </FieldWrapper>
      </FieldsWrapper>
      <ButtonWrapper>
        <CustomButton
          type='submit'
          disabled={Object.keys(errors).length > 0 || !dirty}
        >
          Submit Find Us
        </CustomButton>
      </ButtonWrapper>
    </form>
  );
};
const validationSchema = Yup.object().shape({
  find_us_type: Yup.string().required('Required'),
});
const FindUsForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: (props) => ({
    find_us_type: props.findUs?.find_us_type || '',
  }),
  validationSchema,
  enableReinitialize: true,
  async handleSubmit(values: FormValues, { props }) {
    let newValues = { ...values };
    var bodyFormData = new FormData();
    var newFormData = props.transformToFormData(newValues, bodyFormData, null);
    if (props.findUs?.find_us_id) {
      const findUs = { id: props.findUs?.find_us_id, payload: newFormData };
      props.dispatch(updateFindUs(findUs));
    } else {
      const findUs = newFormData;
      props.dispatch(addFindUs(findUs));
    }
  },
})(InnerForm);
function FindUsDetailForm({ findUs, location }: FindUsDetailFormPropsType) {
  const dispatch = useAppDispatch();
  return (
    <Wrapper>
      <FindUsForm
        transformToFormData={transformToFormData}
        location={location}
        findUs={findUs}
        dispatch={dispatch}
      />
    </Wrapper>
  );
}

export default FindUsDetailForm;
