import CustomButton from '../../../../components/CustomButton';
import transformToFormData from '../../../../utils/transformToFormData';
import { addReview, updateReview } from '../reviewAsyncActions';
import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  Wrapper,
  FormTitle,
  FieldsWrapper,
  FieldWrapper,
  HeaderWrapper,
  ButtonWrapper,
} from './reviewDetailFormStyles';
import { ReviewType } from '../reviewSlice';
import { AppDispatch } from '../../../../store/store';
import { useAppDispatch } from '../../../../store/reduxHooks';

interface ReviewDetailFormPropsType {
  review?: ReviewType | null;
  location: { pathname: string; state: any };
}
interface FormValues {
  review_id?: number | null;
  stars: number | '';
  source: string;
  description: string;
  link: string;
  review_owner: string;
  is_approved: boolean;
}

interface MyFormProps {
  review?: ReviewType | null;
  dispatch: AppDispatch;
  location?: { pathname: string; state: any };
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
interface OtherPropsType {
  review?: ReviewType | null;
  location?: { pathname: string; state: any };
  dispatch: AppDispatch;
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
const InnerForm = (props: OtherPropsType & FormikProps<FormValues>) => {
  const {
    // formik props
    values,
    errors,
    dirty,
    // component props
    handleChange,
    handleSubmit,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <>{console.log('valuse', values)}</>
      <>{console.log('error', errors)}</>
      <HeaderWrapper>
        <FormTitle id='alert-dialog-title'></FormTitle>
      </HeaderWrapper>
      <FieldsWrapper>
        <div>
          {props.review?.review_id && (
            <FieldWrapper>
              <TextField
                type='text'
                size='small'
                label='Review ID'
                fullWidth
                value={props.review?.review_id}
                disabled
              />
            </FieldWrapper>
          )}
          <FieldWrapper>
            <TextField
              required
              type='text'
              fullWidth
              size='small'
              label='Stars'
              name='stars'
              value={values.stars}
              onChange={handleChange}
              error={errors?.stars ? true : false}
              helperText={errors?.stars}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              required
              type='text'
              fullWidth
              size='small'
              label='Source'
              name='source'
              value={values.source}
              onChange={handleChange}
              error={errors?.source ? true : false}
              helperText={errors?.source}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              required
              type='text'
              fullWidth
              size='small'
              label='Reviewer'
              name='review_owner'
              value={values.review_owner}
              onChange={handleChange}
              error={errors?.review_owner ? true : false}
              helperText={errors?.review_owner}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              required
              type='text'
              fullWidth
              multiline
              rows={props.review?.review_id ? 5 : 7}
              size='small'
              label='Source Link'
              name='link'
              value={values.link}
              onChange={handleChange}
              error={errors?.link ? true : false}
              helperText={errors?.link}
            />
          </FieldWrapper>
        </div>
        <div>
          <FieldWrapper>
            <FormControlLabel
              style={{ padding: 0, maxHeight: '39px' }}
              name='is_approved'
              checked={values.is_approved}
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: '#000',
                      margin: 0,
                    },
                  }}
                />
              }
              label='Is review approved to be published?'
              onChange={handleChange}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              multiline
              rows={13}
              size='small'
              name='description'
              label='Description'
              value={values.description}
              onChange={handleChange}
              error={errors?.description ? true : false}
              helperText={errors?.description}
            />
          </FieldWrapper>
        </div>
      </FieldsWrapper>
      <ButtonWrapper>
        <CustomButton
          type='submit'
          disabled={Object.keys(errors).length > 0 || !dirty}
        >
          Submit Review
        </CustomButton>
      </ButtonWrapper>
    </form>
  );
};
const validationSchema = Yup.object().shape({
  stars: Yup.number().required('Required'),
  review_owner: Yup.string().required('Required'),
  source: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});
const ReviewForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: (props) => ({
    stars: props.review?.stars || '',
    review_owner: props.review?.review_owner || '',
    source: props.review?.source || '',
    description: props.review?.description || '',
    link: props.review?.link || '',
    is_approved: props.review?.is_approved || false,
  }),
  validationSchema,
  enableReinitialize: true,
  async handleSubmit(values: FormValues, { props }) {
    var bodyFormData = new FormData();
    let newValues = { ...values };
    var newFormData = props.transformToFormData(newValues, bodyFormData, null);
    if (props.review?.review_id) {
      const review = {
        id: props.review?.review_id,
        payload: newFormData,
      };
      props.dispatch(updateReview(review));
    } else {
      const review = newFormData;
      props.dispatch(addReview(review));
    }
  },
})(InnerForm);
function ReviewDetailForm({ review, location }: ReviewDetailFormPropsType) {
  const dispatch = useAppDispatch();
  return (
    <Wrapper>
      <ReviewForm
        transformToFormData={transformToFormData}
        location={location}
        review={review}
        dispatch={dispatch}
      />
    </Wrapper>
  );
}

export default ReviewDetailForm;
