import { createSlice } from '@reduxjs/toolkit';
import {
  getAllReviews,
  getReviewById,
  addReview,
  updateReview,
  deleteReview,
} from './reviewAsyncActions';
import { history } from '../../../utils/history';
export interface ReviewType {
  review_id: number | null;
  stars: number | null;
  source: string;
  description: string;
  link: string;
  review_owner: string;
  is_approved: boolean;
}
export interface ReviewStateType {
  review: ReviewType | null | undefined;
  allReviews: Array<ReviewType> | null;
  isGettingReview: boolean;
}
const initialState: ReviewStateType = {
  review: null,
  allReviews: [],
  isGettingReview: false,
};
export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    initiateReview: (state) => {
      state.review = {
        review_id: null,
        stars: null,
        source: '',
        description: '',
        link: '',
        review_owner: '',
        is_approved: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllReviews?.pending, (state) => {
      state.isGettingReview = true;
    });
    builder.addCase(getAllReviews?.fulfilled, (state, action: any) => {
      state.isGettingReview = false;
      state.allReviews = action?.payload?.data.data;
    });
    builder.addCase(getAllReviews.rejected, (state, action) => {
      state.isGettingReview = false;
    });
    // getReviewById
    builder.addCase(getReviewById?.pending, (state) => {
      state.isGettingReview = true;
    });
    builder.addCase(getReviewById?.fulfilled, (state, action: any) => {
      state.isGettingReview = false;
      state.review = action?.payload?.data.data as ReviewType;
    });
    builder.addCase(getReviewById.rejected, (state, action: any) => {
      state.isGettingReview = false;
    });
    // addReview
    builder.addCase(addReview?.pending, (state) => {
      state.isGettingReview = true;
    });
    builder.addCase(addReview?.fulfilled, (state, action: any) => {
      state.isGettingReview = false;
      history.navigate('/review', {
        state: {
          id: null,
          pageTitle: 'Review List',
          referencePath: '/review',
          referencePageTitle: 'Review List',
        },
      });
    });
    builder.addCase(addReview.rejected, (state, action: any) => {
      state.isGettingReview = false;
    });
    // updateReview
    builder.addCase(updateReview?.pending, (state) => {
      state.isGettingReview = true;
    });
    builder.addCase(updateReview?.fulfilled, (state, action: any) => {
      state.isGettingReview = false;
      history.navigate('/review', {
        state: {
          id: null,
          pageTitle: 'Review List',
          referencePath: '/review',
          referencePageTitle: 'Review List',
        },
      });
    });
    builder.addCase(updateReview.rejected, (state, action: any) => {
      state.isGettingReview = false;
    });
    // deleteReview
    builder.addCase(deleteReview?.pending, (state) => {
      state.isGettingReview = true;
    });
    builder.addCase(deleteReview?.fulfilled, (state, action: any) => {
      state.isGettingReview = false;
      history.navigate('/review', {
        state: {
          id: null,
          pageTitle: 'Review List',
          referencePath: '/review',
          referencePageTitle: 'Review List',
        },
      });
    });
    builder.addCase(deleteReview.rejected, (state, action: any) => {
      state.isGettingReview = false;
    });
  },
});
export const { initiateReview } = reviewSlice.actions;
const reviewReducer = reviewSlice.reducer;
export default reviewReducer;
