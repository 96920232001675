import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getSubcategoryById, getCategories } from '../subcategoryAsyncActions';
import SubcategoryDetailForm from './SubcategoryDetailForm';
import { initiateSubcategory } from '../subcategorySlice';
function SubcategoryDetailContainer() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { subcategory, allCategories } = useAppSelector(
    (state) => state.subcategoryReducer
  );
  useEffect(() => {
    const id = location.state?.id;
    dispatch(getCategories());
    if (id) {
      dispatch(getSubcategoryById(id));
    } else {
      dispatch(initiateSubcategory());
    }
  }, [dispatch, location?.state]);
  return (
    <SubcategoryDetailForm
      subcategory={subcategory}
      location={location}
      allCategories={allCategories}
    />
  );
}

export default SubcategoryDetailContainer;
