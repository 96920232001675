import CustomButton from '../../../../components/CustomButton';
import transformToFormData from '../../../../utils/transformToFormData';
import { addCity, updateCity } from '../cityAsyncActions';
import { TextField } from '@mui/material';
import { withFormik, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Wrapper,
  FormTitle,
  FieldsWrapper,
  FieldWrapper,
  HeaderWrapper,
  ButtonWrapper,
} from './cityDetailFormStyles';
import { CityType } from '../citySlice';
import { AppDispatch } from '../../../../store/store';
import { useAppDispatch } from '../../../../store/reduxHooks';
interface CityDetailFormPropsType {
  city?: CityType | null;
  location: { pathname: string; state: any };
}
interface FormValues {
  city_id?: number | null;
  city_name?: string | null;
}

interface MyFormProps {
  city?: CityType | null;
  dispatch: AppDispatch;
  location?: { pathname: string; state: any };
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
interface OtherPropsType {
  city?: CityType | null;
  location?: { pathname: string; state: any };
  dispatch: AppDispatch;
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
const InnerForm = (props: OtherPropsType & FormikProps<FormValues>) => {
  const {
    // formik props
    values,
    errors,
    dirty,
    // component props
    handleChange,
    handleSubmit,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <>{console.log('valuse', values)}</>
      <HeaderWrapper>
        <FormTitle id='alert-dialog-title'></FormTitle>
      </HeaderWrapper>
      <FieldsWrapper>
        {props.city?.city_id && (
          <FieldWrapper width='300px'>
            <TextField
              type='text'
              size='small'
              label='City ID'
              fullWidth
              value={props.city?.city_id}
              disabled
            />
          </FieldWrapper>
        )}
        <FieldWrapper width='300px'>
          <TextField
            required
            type='text'
            fullWidth
            size='small'
            name='city_name'
            label='City Option'
            value={values.city_name}
            onChange={handleChange}
            error={errors?.city_name ? true : false}
            helperText={errors?.city_name}
          />
          <ErrorMessage name='city_name' component='div' />
        </FieldWrapper>
      </FieldsWrapper>
      <ButtonWrapper>
        <CustomButton
          type='submit'
          disabled={Object.keys(errors).length > 0 || !dirty}
        >
          Submit City
        </CustomButton>
      </ButtonWrapper>
    </form>
  );
};
const validationSchema = Yup.object().shape({
  city_name: Yup.string().required('Required'),
});
const CityForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: (props) => ({
    city_name: props.city?.city_name || '',
  }),
  validationSchema,
  enableReinitialize: true,
  async handleSubmit(values: FormValues, { props }) {
    let newValues = { ...values };
    var bodyFormData = new FormData();
    var newFormData = props.transformToFormData(newValues, bodyFormData, null);
    if (props.city?.city_id) {
      const city = { id: props.city?.city_id, payload: newFormData };
      props.dispatch(updateCity(city));
    } else {
      const city = newFormData;
      props.dispatch(addCity(city));
    }
  },
})(InnerForm);
function CityDetailForm({ city, location }: CityDetailFormPropsType) {
  const dispatch = useAppDispatch();

  return (
    <Wrapper>
      <CityForm
        transformToFormData={transformToFormData}
        location={location}
        city={city}
        dispatch={dispatch}
      />
    </Wrapper>
  );
}

export default CityDetailForm;
