import CustomButton from '../../../../components/CustomButton';
import transformToFormData from '../../../../utils/transformToFormData';
import { addSubcategory, updateSubcategory } from '../subcategoryAsyncActions';
import { TextField, Autocomplete } from '@mui/material';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  Wrapper,
  FormTitle,
  FieldsWrapper,
  FieldWrapper,
  HeaderWrapper,
  ButtonWrapper,
} from './subcategoryDetailFormStyles';
import { SubcategoryType, CategoryType } from '../subcategorySlice';
import { AppDispatch } from '../../../../store/store';
import { useAppDispatch } from '../../../../store/reduxHooks';
interface SubcategoryDetailFormPropsType {
  subcategory?: SubcategoryType | null;
  allCategories?: Array<CategoryType> | [];
  location: { pathname: string; state: any };
}
interface FormValues {
  subcategory_id?: number | null;
  subcategory_name?: string | null;
  subcategory_description?: string | null;
  category_id?: number | null;
}
interface MyFormProps {
  subcategory?: SubcategoryType | null;
  allCategories?: Array<CategoryType> | [];
  dispatch: AppDispatch;
  location?: { pathname: string; state: any };
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
interface OtherPropsType {
  subcategory?: SubcategoryType | null;
  allCategories?: Array<CategoryType> | [];
  location?: { pathname: string; state: any };
  dispatch: AppDispatch;
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}

const InnerForm = (props: OtherPropsType & FormikProps<FormValues>) => {
  const {
    // formik props
    values,
    errors,
    setFieldValue,
    dirty,
    // component props
    allCategories,
    handleSubmit,
    handleChange,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      {/* <>{console.log('valuse', values)}</> */}
      <HeaderWrapper>
        <FormTitle id='alert-dialog-title'></FormTitle>
      </HeaderWrapper>
      <FieldsWrapper>
        {props.subcategory?.subcategory_id && (
          <FieldWrapper width='300px'>
            <TextField
              fullWidth
              type='text'
              size='small'
              label='Subcategory ID'
              value={props.subcategory?.subcategory_id}
              disabled
            />
          </FieldWrapper>
        )}
        <FieldWrapper width='300px'>
          <Autocomplete
            size='small'
            fullWidth
            limitTags={2}
            disablePortal
            id='combo-box-demo'
            options={allCategories || []}
            value={allCategories?.find(
              (item) => values.category_id === item?.category_id
            )}
            getOptionLabel={(option) => option?.category_name}
            onChange={(_, selectedItem) => {
              setFieldValue('category_id', selectedItem?.category_id);
            }}
            renderInput={(params) => (
              <TextField {...params} label='Category' required />
            )}
          />
        </FieldWrapper>
        <FieldWrapper width='300px'>
          <TextField
            required
            fullWidth
            type='text'
            size='small'
            name='subcategory_name'
            label='Subcategory'
            placeholder='Subcategory'
            value={values.subcategory_name}
            onChange={handleChange}
            error={errors?.subcategory_name ? true : false}
            helperText={errors?.subcategory_name}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            required
            type='text'
            fullWidth
            multiline
            rows={15}
            name='subcategory_description'
            label='Subcategory Description'
            value={values.subcategory_description}
            onChange={handleChange}
            error={errors?.subcategory_description ? true : false}
            helperText={errors?.subcategory_description}
          />
        </FieldWrapper>
      </FieldsWrapper>
      <ButtonWrapper>
        <CustomButton
          type='submit'
          disabled={Object.keys(errors).length > 0 || !dirty}
        >
          Submit Subcategory
        </CustomButton>
      </ButtonWrapper>
    </form>
  );
};
const validationSchema = Yup.object().shape({
  subcategory_name: Yup.string().required('Required'),
  subcategory_description: Yup.string().required('Required'),
  category_id: Yup.number().required('Required'),
});
const SubcategoryForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: (props) => ({
    subcategory_name: props.subcategory?.subcategory_name || '',
    subcategory_description: props.subcategory?.subcategory_description || '',
    category_id: props.subcategory?.category_id || null,
  }),
  validationSchema,
  enableReinitialize: true,
  async handleSubmit(values: FormValues, { props }) {
    let newValues = { ...values };
    var bodyFormData = new FormData();
    var newFormData = props.transformToFormData(newValues, bodyFormData, null);
    if (props.subcategory?.subcategory_id) {
      const subcategory = {
        id: props.subcategory?.subcategory_id,
        payload: newFormData,
      };
      props.dispatch(updateSubcategory(subcategory));
    } else {
      const subcategory = newFormData;
      props.dispatch(addSubcategory(subcategory));
    }
  },
})(InnerForm);
function SubcategoryDetailForm({
  subcategory,
  location,
  allCategories,
}: SubcategoryDetailFormPropsType) {
  const dispatch = useAppDispatch();
  return (
    <Wrapper>
      <SubcategoryForm
        transformToFormData={transformToFormData}
        location={location}
        subcategory={subcategory}
        allCategories={allCategories}
        dispatch={dispatch}
      />
    </Wrapper>
  );
}

export default SubcategoryDetailForm;
