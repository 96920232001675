import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper } from './approachDetailContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getApproachById } from '../approachAsyncActions';
import ApproachDetailForm from './ApproachDetailForm';
import { initiateApproach } from '../approachSlice';
function ApproachDetailContainer() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { approach } = useAppSelector((state) => state.approachReducer);
  useEffect(() => {
    const id = location.state?.id;

    if (id) {
      dispatch(getApproachById(id));
    } else {
      dispatch(initiateApproach());
    }
  }, [dispatch, location?.state]);

  return (
    <Wrapper>
      {approach && (
        <ApproachDetailForm approach={approach} location={location} />
      )}
    </Wrapper>
  );
}

export default ApproachDetailContainer;
