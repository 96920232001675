import React from 'react';
import * as Yup from 'yup';
import ModalImage from 'react-modal-image';
import CustomButton from '../../../../components/CustomButton';
import CustomFileUpload from '../../../../components/CustomFileUpload';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import transformToFormData from '../../../../utils/transformToFormData';
import { TextField, Autocomplete } from '@mui/material';
import { withFormik, FormikProps, ErrorMessage } from 'formik';
import { PatternFormat } from 'react-number-format';
import { addRequest, updateRequest } from '../requestAsyncActions';
import dayjs from 'dayjs';
import {
  Wrapper,
  HeaderWrapper,
  InputContainer,
  Note,
  CustomerImgWrapper,
  UploadImageWrapper,
  FieldsWrapper,
  FieldWrapper,
} from './requestDetailFormStyles';
import { useAppDispatch } from '../../../../store/reduxHooks';
import { RequestType } from '../requestSlice';
import { CityType } from '../../city/citySlice';
import { SubcategoryType } from '../../subcategory/subcategorySlice';
import { FindUsType } from '../../findus/findUsSlice';
import { AppDispatch } from '../../../../store/store';

const Root_Static_URL = process.env.REACT_APP_STATIC_ROOT_URL;
interface RequestDetailFormPropsType {
  request: RequestType | null | undefined;
  location: { pathname: string; state: any };
  allFindUs: Array<FindUsType> | null;
  allCity: Array<CityType> | null;
  allSubcategory: Array<SubcategoryType> | null;
}
interface FormValues {
  request_number?: string;
  request_submission_datetime?: string;
  is_online_request?: boolean;
  has_been_seen?: boolean;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  phone_number_alt?: string;
  visit_schedule_1: string | null;
  visit_schedule_2: string | null;
  visit_schedule_3: string | null;
  note?: string | null;
  media1?: string | null | File;
  media2?: string | null | File;
  media3?: string | null | File;
  contract_id?: number | null;
  find_us_id?: number | null;
  subcategories?: number[];
  address: {
    address_line1?: string;
    address_line2?: string;
    city_id?: number | null;
    province?: string | null;
    postal_code?: string | null;
    country?: string | null;
  };
}
interface MyFormProps {
  request?: RequestType | null;
  allFindUs: Array<FindUsType> | null;
  allCity: Array<CityType> | null;
  allSubcategory: Array<SubcategoryType> | null;
  handleChangeRequest: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => void;
  handleChangeFindUs: (
    key: string,
    findUS: FindUsType,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => void;
  handleChangeSubcategory: (
    subcategories: Array<SubcategoryType>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => void;
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
  dispatch: AppDispatch;
}
interface OtherPropsType {
  request?: RequestType | null;
  allFindUs: Array<FindUsType> | null;
  allCity: Array<CityType> | null;
  allSubcategory: Array<SubcategoryType> | null;
  handleChangeRequest: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => void;
  handleChangeFindUs: (
    key: string,
    findUS: FindUsType,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => void;
  handleChangeSubcategory: (
    subcategories: Array<SubcategoryType>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => void;
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
  dispatch: AppDispatch;
}

const InnerForm = (props: OtherPropsType & FormikProps<FormValues>) => {
  const {
    // formik props
    values,
    touched,
    errors,
    setFieldValue,
    dirty,
    // component props
    request,
    allFindUs,
    allCity,
    allSubcategory,
    handleChangeRequest,
    handleChangeFindUs,
    handleChangeSubcategory,
    handleSubmit,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <HeaderWrapper>
        <DialogTitle id='alert-dialog-title'>
          {'Schedule a visit and design consultation'}
        </DialogTitle>
      </HeaderWrapper>
      <DialogContent>
        <div id='alert-dialog-description'>
          Please fill out below form to schedule a free visit and design
          consultation with our experts.
          <Note>Only fields that have marked with * are required</Note>
        </div>
        <InputContainer>
          <FieldsWrapper>
            <FieldWrapper>
              <TextField
                type='text'
                fullWidth
                size='small'
                name='request_submission_datetime'
                label='Request Submission Datetime'
                value={values.request_submission_datetime}
                disabled
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextField
                required
                type='text'
                fullWidth
                size='small'
                name='first_name'
                label='First Name'
                value={values.first_name}
                onChange={(e) => handleChangeRequest(e, setFieldValue)}
                error={errors?.first_name ? true : false}
                helperText={errors?.first_name}
              />
              <ErrorMessage name='first_name' component='div' />
            </FieldWrapper>
            <FieldWrapper>
              <TextField
                required
                type='text'
                size='small'
                fullWidth
                name='last_name'
                label='Last Nam'
                value={values.last_name}
                onChange={(e) => handleChangeRequest(e, setFieldValue)}
                error={errors?.last_name ? true : false}
                helperText={errors?.last_name}
              />
              <ErrorMessage name='first_name' component='div' />
            </FieldWrapper>
            <FieldWrapper>
              <TextField
                required
                type='text'
                size='small'
                fullWidth
                name='email'
                label='Email'
                value={values.email}
                onChange={(e) => handleChangeRequest(e, setFieldValue)}
              />
              {touched.email && errors.email && <div>{errors.email}</div>}
            </FieldWrapper>
            <FieldWrapper grid='1fr 1fr'>
              <PatternFormat
                required
                type='text'
                id='ExistingGiftCard-CardNumber'
                label='Phone Number'
                name='phone_number'
                size='small'
                value={values.phone_number}
                onChange={(e) => handleChangeRequest(e, setFieldValue)}
                customInput={TextField}
                error={
                  touched.phone_number && errors.phone_number ? true : false
                }
                style={{ width: '100%' }}
                helperText={touched.phone_number && errors.phone_number}
                format='(###) ###-####'
              />
              <PatternFormat
                type='text'
                size='small'
                id='ExistingGiftCard-CardNumber'
                name='phone_number_alt'
                label='Alt Phone Number'
                value={values.phone_number_alt}
                onChange={(e) => handleChangeRequest(e, setFieldValue)}
                customInput={TextField}
                // onBlur={handleBlur}
                error={
                  touched.phone_number_alt && errors.phone_number_alt
                    ? true
                    : false
                }
                style={{ width: '100%' }}
                helperText={touched.phone_number_alt && errors.phone_number_alt}
                format='(###) ###-####'
              />
            </FieldWrapper>
            <FieldWrapper grid='1fr 2fr'>
              <TextField
                required
                type='text'
                fullWidth
                size='small'
                name='address.province'
                label='Province'
                value={values.address.province}
                onChange={(e) => handleChangeRequest(e, setFieldValue)}
                disabled
              />
              <Autocomplete
                disablePortal
                fullWidth
                size='small'
                id='combo-box-demo'
                options={allCity || []}
                getOptionLabel={(option) => option?.city_name}
                value={
                  allCity?.find(
                    (item) => item.city_id === values.address.city_id
                  ) || null
                }
                onChange={(_, selectedItem) =>
                  setFieldValue('address.city_id', selectedItem?.city_id)
                }
                renderInput={(params) => (
                  <TextField {...params} label='City' required />
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextField
                required
                type='text'
                fullWidth
                size='small'
                name='address.address_line1'
                label='Address Line 1'
                value={values.address.address_line1}
                onChange={(e) => handleChangeRequest(e, setFieldValue)}
              />
            </FieldWrapper>
            <FieldWrapper grid='2fr 1fr'>
              <TextField
                type='text'
                fullWidth
                size='small'
                name='address.address_line2'
                label='Address Line 2'
                value={values.address.address_line2}
                onChange={(e) => handleChangeRequest(e, setFieldValue)}
              />
              <TextField
                required
                type='text'
                fullWidth
                size='small'
                name='address.postal_code'
                label='Postal Code'
                value={values.address.postal_code}
                onChange={(e) => handleChangeRequest(e, setFieldValue)}
              />
            </FieldWrapper>
          </FieldsWrapper>
          <FieldsWrapper>
            <FieldWrapper>
              <Autocomplete
                disablePortal
                fullWidth
                size='small'
                id='combo-box-demo'
                options={allFindUs || []}
                value={
                  allFindUs?.find(
                    (item) => item.find_us_id === values.find_us_id
                  ) || null
                }
                getOptionLabel={(option) => option?.find_us_type}
                onChange={(_, selectedItem) => {
                  if (selectedItem)
                    handleChangeFindUs(
                      'find_us_id',
                      selectedItem,
                      setFieldValue
                    );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='How did you find us?'
                    required
                  />
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Autocomplete
                multiple
                fullWidth
                size='small'
                limitTags={2}
                disablePortal
                id='combo-box-demo'
                options={allSubcategory || []}
                value={allSubcategory?.filter((item) =>
                  values.subcategories?.includes(item?.subcategory_id as number)
                )}
                getOptionLabel={(option) => option?.subcategory_name}
                onChange={(_, selectedItem) => {
                  if (selectedItem)
                    handleChangeSubcategory(selectedItem, setFieldValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Area of Interests?' />
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextField
                type='text'
                fullWidth
                multiline
                rows={3}
                name='note'
                label='Note'
                value={values.note}
                onChange={(e) => handleChangeRequest(e, setFieldValue)}
              />
            </FieldWrapper>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FieldWrapper>
                <DateTimePicker
                  label='Option 1'
                  value={
                    (values.visit_schedule_1 &&
                      dayjs(values.visit_schedule_1)) ||
                    null
                  }
                  onChange={(newValue) => {
                    const datetime1 = dayjs(newValue).format(
                      'ddd, MMM D, YYYY h:mm A'
                    );
                    setFieldValue('visit_schedule_1', datetime1);
                  }}
                  sx={{ width: '100%' }}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </FieldWrapper>
              <FieldWrapper>
                <DateTimePicker
                  label='Option 2'
                  value={
                    (values.visit_schedule_2 &&
                      dayjs(values.visit_schedule_2)) ||
                    null
                  }
                  onChange={(newValue) => {
                    const datetime2 = dayjs(newValue).format(
                      'ddd, MMM D, YYYY h:mm A'
                    );
                    setFieldValue('visit_schedule_2', datetime2);
                  }}
                  sx={{ width: '100%' }}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </FieldWrapper>
              <FieldWrapper>
                <DateTimePicker
                  label='Option 3'
                  value={
                    (values.visit_schedule_3 &&
                      dayjs(values.visit_schedule_3)) ||
                    null
                  }
                  onChange={(newValue) => {
                    const datetime3 = dayjs(newValue).format(
                      'ddd, MMM D, YYYY h:mm A'
                    );
                    setFieldValue('visit_schedule_3', datetime3);
                  }}
                  sx={{ width: '100%' }}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </FieldWrapper>
            </LocalizationProvider>
          </FieldsWrapper>
        </InputContainer>
        <UploadImageWrapper>
          <FieldWrapper>
            <CustomFileUpload
              handleUploadFile={(e) => {
                if (e.target.files) {
                  const newFile = e.target.files[0];
                  if (newFile) {
                    setFieldValue('media1', newFile);
                  }
                }
              }}
              handleClearFile={() => setFieldValue('media1', '')}
              fileName={values.media1 as string}
              placehoder='Image of your space'
              setFieldValue={setFieldValue}
              disabled={request?.request_id ? true : false}
            >
              Image 1
            </CustomFileUpload>
            {typeof values.media1 === 'string' && values.media1 && (
              <CustomerImgWrapper>
                <ModalImage
                  small={`${Root_Static_URL}/request/${values.media1}?size=800`}
                  large={`${Root_Static_URL}/request/${values.media1}?size=800`}
                  alt='Cutomer Space 1'
                />
              </CustomerImgWrapper>
            )}
          </FieldWrapper>
          <FieldWrapper>
            <CustomFileUpload
              handleUploadFile={(e) => {
                if (e.target.files) {
                  const newFile = e.target.files[0];
                  if (newFile) {
                    setFieldValue('media2', newFile);
                  }
                }
              }}
              handleClearFile={() => setFieldValue('media2', '')}
              fileName={values.media2 as string}
              placehoder='Image of your space'
              setFieldValue={setFieldValue}
              disabled={request?.request_id ? true : false}
            >
              Image 1
            </CustomFileUpload>
            {typeof values.media2 === 'string' && values.media2 && (
              <CustomerImgWrapper>
                <ModalImage
                  small={`${Root_Static_URL}/request/${values.media2}?size=800`}
                  large={`${Root_Static_URL}/request/${values.media2}?size=800`}
                  alt='Cutomer Space 2'
                />
              </CustomerImgWrapper>
            )}
          </FieldWrapper>
          <FieldWrapper>
            <CustomFileUpload
              handleUploadFile={(e) => {
                if (e.target.files) {
                  const newFile = e.target.files[0];
                  if (newFile) {
                    setFieldValue('media3', newFile);
                  }
                }
              }}
              handleClearFile={() => setFieldValue('media3', '')}
              fileName={values.media3 as string}
              placehoder='Image of your space'
              setFieldValue={setFieldValue}
              disabled={request?.request_id ? true : false}
            >
              Image 1
            </CustomFileUpload>
            {typeof values.media3 === 'string' && values.media3 && (
              <CustomerImgWrapper>
                <ModalImage
                  small={`${Root_Static_URL}/request/${values.media3}?size=800`}
                  large={`${Root_Static_URL}/request/${values.media3}?size=800`}
                  alt='Cutomer Space 3'
                />
              </CustomerImgWrapper>
            )}
          </FieldWrapper>
        </UploadImageWrapper>
      </DialogContent>
      <DialogActions style={{ padding: '0 20px 20px 20px' }}>
        <CustomButton
          type='submit'
          disabled={Object.keys(errors).length > 0 || !dirty}
        >
          Submit Request
        </CustomButton>
      </DialogActions>
    </form>
  );
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  find_us_id: Yup.number().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  address: Yup.object().shape({
    address_line1: Yup.string().required('Required'),
    city_id: Yup.number().required('Required'),
    province: Yup.string().required('Required'),
  }),
});
const RequestForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: (props) => ({
    request_number: props.request?.request_number || '',
    request_submission_datetime:
      props.request?.request_submission_datetime ||
      dayjs().format('ddd, MMM D, YYYY h:mm A'),
    is_online_request: props.request?.is_online_request || false,
    has_been_seen: true,
    first_name: props.request?.first_name || '',
    last_name: props.request?.last_name || '',
    email: props.request?.email || '',
    phone_number: props.request?.phone_number || '',
    phone_number_alt: props.request?.phone_number_alt || '',
    visit_schedule_1: props.request?.visit_schedule_1 || null,
    visit_schedule_2: props.request?.visit_schedule_2 || null,
    visit_schedule_3: props.request?.visit_schedule_3 || null,
    note: props.request?.note || '',
    media1: props.request?.media1,
    media2: props.request?.media2,
    media3: props.request?.media3,
    find_us_id: props.request?.find_us_id,
    contract_id: props.request?.contract_id,
    oldSubcategories: props.request?.subcategories || [],
    subcategories: props.request?.subcategories,
    address: {
      address_line1: props.request?.address_line1 || '',
      address_line2: props.request?.address_line2 || '',
      city_id: props.request?.city_id,
      province: props.request?.province || '',
      postal_code: props.request?.postal_code || '',
      country: props.request?.country || '',
    },
  }),
  enableReinitialize: true,
  validationSchema,
  async handleSubmit(values: FormValues, { props }) {
    let newValues = { ...values };
    newValues.request_submission_datetime = dayjs().format(
      'ddd, MMM D, YYYY h:mm A'
    );
    const currentId = props.request?.request_id;
    var bodyFormData = new FormData();
    var newFormData = props.transformToFormData(newValues, bodyFormData, null);
    if (currentId) {
      props.dispatch(updateRequest({ id: currentId, payload: newFormData }));
    } else {
      props.dispatch(addRequest(newFormData));
    }
  },
})(InnerForm);
function RequestDetailForm({
  request,
  allFindUs,
  allCity,
  allSubcategory,
}: RequestDetailFormPropsType) {
  const dispatch = useAppDispatch();
  const handleChangeRequest = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const { name, value } = e.target;
    if (setFieldValue) setFieldValue(name, value);
  };
  const handleChangeFindUs = (
    key: string,
    findUS: FindUsType,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue(key, findUS.find_us_id);
  };
  const handleChangeSubcategory = (
    subcategories: Array<SubcategoryType>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const newSubcategories = subcategories.map((sub) => sub.subcategory_id);
    setFieldValue('subcategories', newSubcategories);
  };

  return (
    <Wrapper>
      <RequestForm
        allFindUs={allFindUs}
        allCity={allCity}
        allSubcategory={allSubcategory}
        handleChangeRequest={handleChangeRequest}
        handleChangeFindUs={handleChangeFindUs}
        handleChangeSubcategory={handleChangeSubcategory}
        transformToFormData={transformToFormData}
        request={request}
        dispatch={dispatch}
      />
    </Wrapper>
  );
}

export default RequestDetailForm;
