import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface StyledProps {
  padding: string | undefined | null;
  margin: string | undefined | null;
  backgroundColor: string | undefined | null;
  color: string | undefined;
  hoverBackColor: string | undefined;
  hoverTextColor: string | undefined;
  disabledBackColor: string | undefined;
  disabledTextColor: string | undefined;
  type?: 'submit' | 'reset' | 'button' | undefined;
}
interface ButtonInputProps {
  children?: ReactNode;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  color?: string;
  hoverBackColor?: string;
  hoverTextColor?: string;
  disabledBackColor?: string;
  disabledTextColor?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
}
const StyledButton = styled.button<StyledProps>`
  padding: ${(props) => props.padding || '10px 15px'};
  margin: ${(props) => props.padding || 0};
  color: ${(props) => props.color || '#e4cd84'};
  background-color: ${(props) => props.backgroundColor || '#000000'};
  cursor: pointer;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  &:hover {
    background-color: ${(props) => props.hoverBackColor || '#66666e'};
    color: ${(props) => props.hoverTextColor || '#fed766'};
  }
  &:disabled {
    cursor: default;
    background-color: ${(props) => props.disabledBackColor || '#e6e8e6'};
    color: ${(props) => props.disabledTextColor || '#7e7f83'};
  }
`;
const CustomButton: FC<ButtonInputProps> = ({
  children,
  padding,
  margin,
  backgroundColor,
  color,
  hoverBackColor,
  hoverTextColor,
  disabledBackColor,
  disabledTextColor,
  onClick,
  disabled,
  type,
}) => {
  return (
    <StyledButton
      padding={padding}
      margin={margin}
      backgroundColor={backgroundColor}
      color={color}
      hoverBackColor={hoverBackColor}
      hoverTextColor={hoverTextColor}
      disabledBackColor={disabledBackColor}
      disabledTextColor={disabledTextColor}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;
