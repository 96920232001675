import CustomButton from '../../../../components/CustomButton';
import transformToFormData from '../../../../utils/transformToFormData';
import { addApproach, updateApproach } from '../approachAsyncActions';
import { TextField } from '@mui/material';
import ModalImage from 'react-modal-image';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  Wrapper,
  FormTitle,
  FieldsWrapper,
  FieldWrapper,
  HeaderWrapper,
  ButtonWrapper,
  CustomerImgWrapper,
} from './approachDetailFormStyles';
import { ApproachType } from '../approachSlice';
import { AppDispatch } from '../../../../store/store';
import { useAppDispatch } from '../../../../store/reduxHooks';
import CustomFileUpload from '../../../../components/CustomFileUpload';

const Root_Static_URL = process.env.REACT_APP_STATIC_ROOT_URL;
interface ApproachDetailFormPropsType {
  approach?: ApproachType | null;
  location: { pathname: string; state: any };
}
interface FormValues {
  approach_id?: number | null;
  step: number | null;
  title?: string | null;
  summary: string;
  description: string;
  media: string | null;
}

interface MyFormProps {
  approach?: ApproachType | null;
  dispatch: AppDispatch;
  location?: { pathname: string; state: any };
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
interface OtherPropsType {
  approach?: ApproachType | null;
  location?: { pathname: string; state: any };
  dispatch: AppDispatch;
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
const InnerForm = (props: OtherPropsType & FormikProps<FormValues>) => {
  const {
    // formik props
    values,
    errors,
    dirty,
    // component props
    setFieldValue,
    handleChange,
    handleSubmit,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <HeaderWrapper>
        <FormTitle id='alert-dialog-title'></FormTitle>
      </HeaderWrapper>
      <FieldsWrapper>
        <div>
          {props.approach?.approach_id && (
            <FieldWrapper>
              <TextField
                type='text'
                size='small'
                label='Approach ID'
                fullWidth
                value={props.approach?.approach_id}
                disabled
              />
            </FieldWrapper>
          )}
          <FieldWrapper>
            <TextField
              required
              type='text'
              fullWidth
              size='small'
              name='step'
              label='Step'
              value={values.step}
              onChange={handleChange}
              error={errors?.step ? true : false}
              helperText={errors?.step}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              required
              multiline
              rows={5}
              type='text'
              fullWidth
              size='small'
              name='title'
              label='Title'
              value={values.title}
              onChange={handleChange}
              error={errors?.title ? true : false}
              helperText={errors?.title}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              required
              type='text'
              fullWidth
              multiline
              rows={5}
              size='small'
              name='summary'
              label='Summary'
              value={values.summary}
              onChange={handleChange}
              error={errors?.summary ? true : false}
              helperText={errors?.summary}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              multiline
              rows={10}
              size='small'
              name='description'
              label='Description'
              value={values.description}
              onChange={handleChange}
              error={errors?.description ? true : false}
              helperText={errors?.description}
            />
          </FieldWrapper>
        </div>
        <div>
          <FieldWrapper>
            <CustomFileUpload
              handleUploadFile={(e) => {
                if (e.target.files) {
                  const newFile = e.target.files[0];
                  if (newFile) {
                    setFieldValue('media', newFile);
                  }
                }
              }}
              handleClearFile={() => setFieldValue('media', '')}
              fileName={values.media}
              placehoder='Approache Image'
              setFieldValue={setFieldValue}
            >
              Image
            </CustomFileUpload>
            {typeof values.media === 'string' && values.media && (
              <CustomerImgWrapper>
                <ModalImage
                  small={`${Root_Static_URL}/approach/${values.media}?size=800`}
                  large={`${Root_Static_URL}/approach/${values.media}?size=800`}
                  alt='Cutomer Space 3'
                />
              </CustomerImgWrapper>
            )}
            {errors.media && (
              <div
                style={{
                  color: 'red',
                  textAlign: 'left',
                  marginTop: '8px',
                  marginLeft: '5px',
                  width: '100%',
                  fontSize: '12px',
                }}
              >
                {errors.media}
              </div>
            )}
          </FieldWrapper>
        </div>
      </FieldsWrapper>
      <ButtonWrapper>
        <CustomButton
          type='submit'
          disabled={Object.keys(errors).length > 0 || !dirty}
        >
          Submit Approach
        </CustomButton>
      </ButtonWrapper>
    </form>
  );
};
const validFileExtensions = ['webp'];
const validationSchema = Yup.object().shape({
  step: Yup.number().required('Required'),
  title: Yup.string().required('Required'),
  summary: Yup.string().required('Required'),
  media: Yup.mixed()
    .required('Required')
    .test('is-valid-type', 'Not a valid image type', (value) => {
      let isValid = true;
      if (value instanceof File) {
        const selectedFileType = value.name.split('.').pop();
        isValid = validFileExtensions.includes(selectedFileType as string);
      } else {
        if (typeof value === 'string') {
          const selectedFileType = value?.split('.').pop();
          isValid = validFileExtensions.includes(selectedFileType as string);
        }
      }
      return isValid;
    }),
});
const ApproachForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: (props) => ({
    step: props.approach?.step || null,
    title: props.approach?.title || '',
    summary: props.approach?.summary || '',
    description: props.approach?.description || '',
    media: props.approach?.media || '',
  }),
  validationSchema,
  enableReinitialize: true,
  async handleSubmit(values: FormValues, { props }) {
    var bodyFormData = new FormData();
    let newValues = { ...values };
    var newFormData = props.transformToFormData(newValues, bodyFormData, null);
    if (props.approach?.approach_id) {
      const approach = {
        id: props.approach?.approach_id,
        payload: newFormData,
      };
      props.dispatch(updateApproach(approach));
    } else {
      const approach = newFormData;
      props.dispatch(addApproach(approach));
    }
  },
})(InnerForm);
function ApproachDetailForm({
  approach,
  location,
}: ApproachDetailFormPropsType) {
  const dispatch = useAppDispatch();
  return (
    <Wrapper>
      <ApproachForm
        transformToFormData={transformToFormData}
        location={location}
        approach={approach}
        dispatch={dispatch}
      />
    </Wrapper>
  );
}

export default ApproachDetailForm;
