import CustomButton from '../../../../components/CustomButton';
import transformToFormData from '../../../../utils/transformToFormData';
import { addJumbotron, updateJumbotron } from '../jumbotronAsyncActions';
import {
  TextField,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ModalImage from 'react-modal-image';
import { withFormik, FormikProps } from 'formik';
import { SubcategoryType } from '../../subcategory/subcategorySlice';
import * as Yup from 'yup';
import {
  Wrapper,
  FormTitle,
  FieldsWrapper,
  FieldWrapper,
  HeaderWrapper,
  ButtonWrapper,
  CustomerImgWrapper,
} from './jumbotronDetailFormStyles';
import { JumbotronType } from '../jumbotronSlice';
import { AppDispatch } from '../../../../store/store';
import { useAppDispatch } from '../../../../store/reduxHooks';
import CustomFileUpload from '../../../../components/CustomFileUpload';

const Root_Static_URL = process.env.REACT_APP_STATIC_ROOT_URL;
interface JumbotronDetailFormPropsType {
  jumbotron?: JumbotronType | null;
  location: { pathname: string; state: any };
  allSubcategory: Array<SubcategoryType> | null;
}
interface FormValues {
  jumbotron_id?: number | null;
  title?: string | null;
  subtitle?: string | null;
  media: string | null;
  is_main_jumbotron: boolean;
  subcategory_id: number | null;
}

interface MyFormProps {
  jumbotron?: JumbotronType | null;
  dispatch: AppDispatch;
  location?: { pathname: string; state: any };
  allSubcategory: Array<SubcategoryType> | null;
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
interface OtherPropsType {
  jumbotron?: JumbotronType | null;
  location?: { pathname: string; state: any };
  allSubcategory: Array<SubcategoryType> | null;
  dispatch: AppDispatch;
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
const InnerForm = (props: OtherPropsType & FormikProps<FormValues>) => {
  const {
    // formik props
    values,
    errors,
    dirty,
    // component props
    allSubcategory,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <>{console.log('valuse', values)}</>
      <>{console.log('error', errors)}</>
      <HeaderWrapper>
        <FormTitle id='alert-dialog-title'></FormTitle>
      </HeaderWrapper>
      <FieldsWrapper>
        {props.jumbotron?.jumbotron_id && (
          <FieldWrapper width='300px'>
            <TextField
              type='text'
              size='small'
              label='Jumbotron ID'
              fullWidth
              value={props.jumbotron?.jumbotron_id}
              disabled
            />
          </FieldWrapper>
        )}
        <FieldWrapper width='300px'>
          <TextField
            required
            type='text'
            fullWidth
            size='small'
            name='title'
            label='Jumbotron Title'
            value={values.title}
            onChange={handleChange}
            error={errors?.title ? true : false}
            helperText={errors?.title}
          />
        </FieldWrapper>
        <FieldWrapper width='300px'>
          <TextField
            type='text'
            fullWidth
            multiline
            rows={5}
            size='small'
            name='subtitle'
            label='Jumbotron Subtitle'
            value={values.subtitle}
            onChange={handleChange}
            error={errors?.subtitle ? true : false}
            helperText={errors?.subtitle}
          />
        </FieldWrapper>
        <FieldWrapper width='300px'>
          <Autocomplete
            size='small'
            fullWidth
            limitTags={2}
            disablePortal
            id='combo-box-demo'
            options={allSubcategory || []}
            value={allSubcategory?.find(
              (item: SubcategoryType) =>
                values.subcategory_id === item?.subcategory_id
            )}
            getOptionLabel={(option) => option?.subcategory_name}
            onChange={(_, selectedItem) => {
              setFieldValue('subcategory_id', selectedItem?.subcategory_id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Subcategory'
                required
                error={errors?.subcategory_id ? true : false}
                helperText={errors?.subcategory_id}
              />
            )}
          />
        </FieldWrapper>
        <FieldWrapper width='300px'>
          <FormControlLabel
            // style={{ padding: 0, background: 'pink', letterSpacing: '2px' }}
            name='is_main_jumbotron'
            checked={values.is_main_jumbotron}
            control={
              <Checkbox
                sx={{
                  // color: '#000',
                  '&.Mui-checked': {
                    color: '#000',
                  },
                }}
              />
            }
            label='Is in home page jumbotron list?'
            onChange={handleChange}
          />
        </FieldWrapper>
        <FieldWrapper width='500px'>
          <CustomFileUpload
            handleUploadFile={(e) => {
              if (e.target.files) {
                const newFile = e.target.files[0];
                if (newFile) {
                  setFieldValue('media', newFile);
                }
              }
            }}
            handleClearFile={() => setFieldValue('media', '')}
            fileName={values.media}
            placehoder='Jumbotrone Image'
            setFieldValue={setFieldValue}
          >
            Image
          </CustomFileUpload>
          {typeof values.media === 'string' && values.media && (
            <CustomerImgWrapper>
              <ModalImage
                small={`${Root_Static_URL}/jumbo/${values.media}?size=800`}
                large={`${Root_Static_URL}/jumbo/${values.media}?size=800`}
                alt='Cutomer Space 3'
              />
            </CustomerImgWrapper>
          )}
          {errors.media && (
            <div
              style={{
                color: 'red',
                textAlign: 'left',
                marginTop: '8px',
                marginLeft: '5px',
                width: '100%',
                fontSize: '12px',
              }}
            >
              {errors.media}
            </div>
          )}
        </FieldWrapper>
      </FieldsWrapper>
      <ButtonWrapper>
        <CustomButton
          type='submit'
          disabled={Object.keys(errors).length > 0 || !dirty}
        >
          Submit Jumbotron
        </CustomButton>
      </ButtonWrapper>
    </form>
  );
};
const validFileExtensions = ['webp'];
const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  media: Yup.mixed()
    .required('Required')
    .test('is-valid-type', 'Not a valid image type', (value) => {
      let isValid = true;
      if (value instanceof File) {
        const selectedFileType = value.name.split('.').pop();
        isValid = validFileExtensions.includes(selectedFileType as string);
        console.log('1', isValid, selectedFileType);
      } else {
        if (typeof value === 'string') {
          const selectedFileType = value?.split('.').pop();
          isValid = validFileExtensions.includes(selectedFileType as string);
          console.log('2', isValid, value);
        }
      }
      console.log('3', isValid);
      return isValid;
    }),
  is_main_jumbotron: Yup.boolean().required('Required'),
  subcategory_id: Yup.number().required('Required'),
});
const JumbotronForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: (props) => ({
    title: props.jumbotron?.title || '',
    subtitle: props.jumbotron?.subtitle || '',
    media: props.jumbotron?.media || '',
    is_main_jumbotron: props.jumbotron?.is_main_jumbotron || false,
    subcategory_id: props.jumbotron?.subcategory_id || null,
  }),
  validationSchema,
  enableReinitialize: true,
  async handleSubmit(values: FormValues, { props }) {
    var bodyFormData = new FormData();
    let newValues = { ...values };
    var newFormData = props.transformToFormData(newValues, bodyFormData, null);
    if (props.jumbotron?.jumbotron_id) {
      const jumbotron = {
        id: props.jumbotron?.jumbotron_id,
        payload: newFormData,
      };
      props.dispatch(updateJumbotron(jumbotron));
    } else {
      const jumbotron = newFormData;
      props.dispatch(addJumbotron(jumbotron));
    }
  },
})(InnerForm);
function JumbotronDetailForm({
  jumbotron,
  location,
  allSubcategory,
}: JumbotronDetailFormPropsType) {
  const dispatch = useAppDispatch();
  return (
    <Wrapper>
      <JumbotronForm
        transformToFormData={transformToFormData}
        location={location}
        jumbotron={jumbotron}
        allSubcategory={allSubcategory}
        dispatch={dispatch}
      />
    </Wrapper>
  );
}

export default JumbotronDetailForm;
