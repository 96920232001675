import React, { useEffect, useState } from 'react';
import { Wrapper } from './cityListContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getAllCity } from '../cityAsyncActions';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../../components/CustomTable';
import CustomConfirmationModal from '../../../../components/CustomConfirmationModal';
import { deleteCity } from '../cityAsyncActions';

function CityListContainer() {
  const navigate = useNavigate();
  const columnHeaders = ['ID', 'City', 'Delete'];
  const columnNames = ['city_id', 'city_name', ''];
  const columnTypes = ['string', 'string', 'delete'];
  const columnWidth = ['10%', '82%', '8%'];
  const searchFileds = ['city_name'];
  const columnHeaderIsSortableList = [true, true, false];
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>();
  const { allCity } = useAppSelector((state) => state.cityReducer);
  useEffect(() => {
    dispatch(getAllCity());
  }, [dispatch]);
  const handleClickRow = (item: any) => {
    navigate('/city-detail', {
      state: {
        id: item?.city_id?.toString(),
        pageTitle: 'Edit City',
        referencePath: '/city',
        referencePageTitle: 'City List',
      },
    });
  };
  const handleButtonClick = () => {
    navigate('/city-detail', {
      state: {
        id: null,
        pageTitle: 'Create City',
        referencePath: '/city',
        referencePageTitle: 'City List',
      },
    });
  };
  const handleDeleteItem = (item: any) => {
    setSelectedDeleteId(item.city_id);
    setIsOpen(true);
  };
  const handleSubmitDelete = () => {
    if (selectedDeleteId) {
      dispatch(deleteCity(selectedDeleteId));
    }
    setIsOpen(false);
  };
  return (
    <Wrapper>
      <CustomTable
        dataList={allCity}
        columnHeaders={columnHeaders}
        columnHeaderIsSortableList={columnHeaderIsSortableList}
        columnNames={columnNames}
        columnWidth={columnWidth}
        columnTypes={columnTypes}
        placeholder='Enter type'
        handleClickRow={handleClickRow}
        handleButtonClick={handleButtonClick}
        buttonText={'Create City'}
        searchFields={searchFileds}
        handleDeleteItem={handleDeleteItem}
      />
      {isOpen && (
        <CustomConfirmationModal
          title={'Confirm Delete City'}
          message={
            'By clicking confiming this city will be removed permanently. \nIf you are sure press confirm.'
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleAction={handleSubmitDelete}
        />
      )}
    </Wrapper>
  );
}

export default CityListContainer;
