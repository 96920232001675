import { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { TextField, InputAdornment, Button } from '@mui/material';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface StyledProps {
  padding?: string | null;
  margin?: string | null;
  height?: string;
  backgroundcolor?: string;
  textcolor?: string;
  hoverbackcolor?: string;
  hovertextcolor?: string;
  disabledbackcolor?: string;
  disabledtextcolor?: string;
  component?: string;
}
interface ButtonInputProps {
  children?: ReactNode;
  padding?: string;
  margin?: string;
  backgroundcolor?: string;
  color?: string;
  height?: string;
  hoverbackcolor?: string;
  hovertextcolor?: string;
  disabledbackcolor?: string;
  disabledtextcolor?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  handleUploadFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearFile: () => void;
  setFieldValue: (field: string, value: any) => void;
  fileName?: string | null;
  placehoder?: string;
  isOptional?: boolean;
}
const UploadButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0;
  width: 100%;
  padding: 0;
  margin: 0;
`;
const UploadButton = styled(Button)<StyledProps>`
  && {
    margin: 0;
    border: none;
    outline: none;
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    height: ${(props) => props.height || '40px'};
    color: ${(props) => props.textcolor || '#e4cd84'};
    background-color: ${(props) => props.backgroundcolor || '#000000'};
    cursor: pointer;
    text-transform: capitalize;
    &:hover {
      background-color: ${(props) => props.hoverbackcolor || '#66666e'};
      color: ${(props) => props.hovertextcolor || '#fed766'};
    }
    &:disabled {
      cursor: default;
      background-color: ${(props) => props.disabledbackcolor || '#e6e8e6'};
      color: ${(props) => props.disabledtextcolor || '#7e7f83'};
    }
  }
`;
const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  cursor: pointer;
`;
const CustomFileUpload: FC<ButtonInputProps> = ({
  children,
  padding,
  margin,
  backgroundcolor,
  color,
  hoverbackcolor,
  hovertextcolor,
  disabledbackcolor,
  disabledtextcolor,
  disabled,
  height,
  handleUploadFile,
  handleClearFile,
  fileName,
  placehoder,
}) => {
  const [localFileName, setLocalFileName] = useState(fileName);
  return (
    <UploadButtonWrapper>
      <UploadButton
        variant='contained'
        component='label'
        padding={padding}
        margin={margin}
        backgroundcolor={backgroundcolor}
        textcolor={color}
        hoverbackcolor={hoverbackcolor}
        hovertextcolor={hovertextcolor}
        disabledbackcolor={disabledbackcolor}
        disabledtextcolor={disabledtextcolor}
        disabled={disabled}
        height={height}
        type='button'
      >
        {children}
        <input
          type='file'
          hidden
          onChange={(e) => {
            if (e.target.files) {
              handleUploadFile(e);
              const newFile = e.target.files[0];
              setLocalFileName(newFile.name);
            }
          }}
        />
      </UploadButton>
      <TextField
        size='small'
        fullWidth
        value={localFileName}
        placeholder={placehoder}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <CloseIcon
                icon={faTimesCircle}
                onClick={() => {
                  handleClearFile();
                  setLocalFileName('');
                }}
              />
            </InputAdornment>
          ),
          style: {
            borderLeft: 'none',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: '40px',
          },
        }}
      />
    </UploadButtonWrapper>
  );
};

export default CustomFileUpload;
