import store from './store/store';
import axios from 'axios';
import moment from 'moment';

const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export const api = axios.create({
  baseURL: ROOT_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

api.interceptors.request.use((request) => {
  request.headers['x-access-token'] = localStorage.getItem('token');
  request.headers['content-type'] = '*';
  return request;
});

api.interceptors.response.use(
  (response) => {
    // const createdAt = moment();
    // localStorage.setItem('createdAt', createdAt);
    // store.dispatch({
    //   type: UPDATE_CREATED_AT_TIMER,
    //   createdAt: createdAt,
    // });

    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      //store.dispatch({
      //  type: 'CREATE_ALERT',
      //  alert: {
      //    type: 'error',
      //    message: 'You do not have access to this route'
      //  }
      //});
    }
    // else if (error.response.status == 400) {
    //   store.dispatch({
    //     type: 'CREATE_ALERT',
    //     alert: {
    //       type: 'error',
    //       message: 'Invalid form data. Please contact Admin.'
    //     }
    //   });
    // }
    else if (error.response.status === 500) {
      //   store.dispatch({
      //     type: 'CREATE_ALERT',
      //     alert: {
      //       type: 'error',
      //       message: 'Server error. Please contact Admin',
      //     },
      //   });
    }
    // else {
    //   store.dispatch({
    //     type: 'CREATE_ALERT',
    //     alert: {type: 'error', message: 'Unknown Error. Please contact Admin'}
    //   });
    // }
    return Promise.reject(error);
  }
);

export default api;
