import { Collapse } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { updateMenu } from './menuSlice';
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import {
  Wrapper,
  MenuSectionWrapper,
  SubMenuSectionWrapper,
  SubLinkWrapper,
  LinkContainer,
  LinkWrapper,
  CustomLink,
  CustomIcon,
} from './menuContainerStyles';

type MenuItemType = {
  id: number;
  title: string;
  path: string;
  icon: any;
  isOpen: boolean;
  subMenu: Array<{
    id: number;
    title: string;
    path: string;
    state: { id?: null | string; pageTitle: string };
    isSelected: boolean;
  }>;
};

type MenuType = Array<MenuItemType>;

function MenuContainer() {
  const dispatch = useAppDispatch();
  const { menu } = useAppSelector((state) => state.menuReducer);
  const handleMenuClick = (index: number) => {
    let newMenu: MenuType = [];
    menu.forEach((menuItem, menuIndex) => {
      let newIsOpen: boolean;
      if (menuIndex === index) {
        newIsOpen = !menu[index].isOpen;
      } else {
        newIsOpen = false;
      }
      newMenu.push({ ...menuItem, isOpen: newIsOpen });
    });
    dispatch(updateMenu(newMenu));
  };
  return (
    <Wrapper>
      {menu.map((item, index) => (
        <MenuSectionWrapper key={`menuItem-${index}`}>
          <LinkContainer key={item.id} onClick={() => handleMenuClick(index)}>
            <CustomIcon icon={item.icon} />
            <LinkWrapper>{item.title}</LinkWrapper>
            <CustomIcon
              icon={item.isOpen ? faChevronDown : faChevronRight}
              onClick={() => handleMenuClick(index)}
            />
          </LinkContainer>
          {item.subMenu.length > 0 && (
            <Collapse in={item.isOpen}>
              <SubMenuSectionWrapper>
                {item.subMenu.map((subMenu, i) => (
                  <SubLinkWrapper key={`submenu-${i}`}>
                    <CustomLink to={subMenu.path} state={subMenu.state}>
                      {subMenu.title}
                    </CustomLink>
                  </SubLinkWrapper>
                ))}
              </SubMenuSectionWrapper>
            </Collapse>
          )}
        </MenuSectionWrapper>
      ))}
    </Wrapper>
  );
}

export default MenuContainer;
