import React, { useEffect, useState } from 'react';
import { Wrapper } from './jumbotonListContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getAllJumbotron } from '../jumbotronAsyncActions';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../../components/CustomTable';
import CustomConfirmationModal from '../../../../components/CustomConfirmationModal';
import { deleteJumbotron } from '../jumbotronAsyncActions';
import { getAllSubcategory } from '../../subcategory/subcategoryAsyncActions';
function JumbotronListContainer() {
  const navigate = useNavigate();
  const columnHeaders = [
    'ID',
    'Title',
    'Subcategory',
    'Is Main Jombo',
    'Delete',
  ];
  const columnNames = [
    'jumbotron_id',
    'title',
    'subcategory_name',
    'is_main_jumbotron',
    '',
  ];
  const columnTypes = ['string', 'string', 'string', 'boolean', 'delete'];
  const columnWidth = ['60px', 'auto', '140px', '150px', '60px'];
  const searchFileds = ['title'];
  const columnHeaderIsSortableList = [true, true, true, true, false];
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>();
  const { allJumbotrons } = useAppSelector((state) => state.jumbotronReducer);
  useEffect(() => {
    dispatch(getAllSubcategory());
    dispatch(getAllJumbotron());
  }, [dispatch]);
  const handleClickRow = (item: any) => {
    navigate('/jumbotron-detail', {
      state: {
        id: item?.jumbotron_id?.toString(),
        pageTitle: 'Edit Jumbotron',
        referencePath: '/jumbotron',
        referencePageTitle: 'Jumbotron List',
      },
    });
  };
  const handleButtonClick = () => {
    navigate('/jumbotron-detail', {
      state: {
        id: null,
        pageTitle: 'Create Jumbotron',
        referencePath: '/jumbotron',
        referencePageTitle: 'Jumbotron List',
      },
    });
  };
  const handleDeleteItem = (item: any) => {
    setSelectedDeleteId(item.jumbotron_id);
    setIsOpen(true);
  };
  const handleSubmitDelete = () => {
    if (selectedDeleteId) {
      dispatch(deleteJumbotron(selectedDeleteId));
    }
    setIsOpen(false);
  };
  return (
    <Wrapper>
      <CustomTable
        dataList={allJumbotrons}
        columnHeaders={columnHeaders}
        columnHeaderIsSortableList={columnHeaderIsSortableList}
        columnNames={columnNames}
        columnWidth={columnWidth}
        columnTypes={columnTypes}
        placeholder='Enter type'
        handleClickRow={handleClickRow}
        handleButtonClick={handleButtonClick}
        buttonText={'Create Jumbotron'}
        searchFields={searchFileds}
        handleDeleteItem={handleDeleteItem}
      />
      {isOpen && (
        <CustomConfirmationModal
          title={'Confirm Delete Jumbotron'}
          message={
            'By clicking confiming this jumbotron will be removed permanently. \nIf you are sure press confirm.'
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleAction={handleSubmitDelete}
        />
      )}
    </Wrapper>
  );
}

export default JumbotronListContainer;
