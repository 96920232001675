import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper } from './adsDetailContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getADSById } from '../adsAsyncActions';
import ADSDetailForm from './ADSDetailForm';
import { initiateADS } from '../adsSlice';
function ADSDetailContainer() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { ads } = useAppSelector((state) => state.adsReducer);
  useEffect(() => {
    const id = location.state?.id;
    if (id) {
      dispatch(getADSById(id));
    } else {
      dispatch(initiateADS());
    }
  }, [dispatch, location?.state]);

  return (
    <Wrapper>
      <ADSDetailForm ads={ads} location={location} />
    </Wrapper>
  );
}

export default ADSDetailContainer;
