import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { CSVLink } from 'react-csv';
import CustomButton from './CustomButton';
import { TextField, Checkbox } from '@mui/material';
import PaginationControl from './PaginationControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
const Wrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 100%;
`;

const SearchWrapper = styled.div`
  margin: 15px 0 25px 0;
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-gap: 40px;
`;

const SortWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  margin-left: 7px;
`;
const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
const THead = styled.thead`
  font-size: 15px;
  height: 80px;
`;
const TBody = styled.tbody`
  font-size: 14px;
`;
const TrHead = styled.tr`
  border-bottom: 1px solid #e1e1e1;
`;
const Th = styled.th<OptionalStylesProps>`
  width: ${(props) => props.width || '100%'};
  padding: 10px;
  text-align: left;
`;
const Tr = styled.tr<OptionalStylesProps>`
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #e1e1e1;
  background-color: ${(props) => (props.hasBack ? '#f7f7fa' : 'transparent')};
  font-weight: ${(props) => (props.hasBeenSeen ? 'normal' : 700)};
  &:hover {
    cursor: pointer;
    background-color: #e5e4e2;
  }
`;
const Td = styled.td<OptionalStylesProps>`
  min-width: ${(props) => props.width || '100%'};
  text-align: ${(props) => props.align || 'left'};
  padding-left: 10px;
  word-wrap: break-word;
`;
const Image = styled.img`
  width: 100%;
  background-color: #d3d3d3;
`;
const IconWrapper = styled.div`
  display: flex;
  /* justify-content: center; */
  margin-left: 17px;
  align-items: center;
`;
const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  z-index: 10;
`;
function paginate(items: Array<any>, PageNumber: number, pageSize: number) {
  const startIndex = (PageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
}
interface OptionalStylesProps {
  hasBack?: boolean | null;
  hasBeenSeen?: boolean | null;
  width?: string;
}
export interface LabelKeyObject {
  label: string;
  key: string;
}
export type Headers = LabelKeyObject[] | string[];
interface TableInputProps {
  dataList: Array<any> | null;
  columnHeaders: Array<string>;
  columnHeaderIsSortableList: Array<boolean>;
  columnNames: Array<string>;
  columnTypes: Array<string>;
  columnWidth: Array<string>;
  searchFields?: Array<string> | null;
  handleClickRow: (x: any) => void;
  exportHeaders?: Headers | undefined;
  exprotData?: Array<any> | null;
  exportFileName?: string;
  totalCheckbox?: boolean;
  setTotalCheckbox?: (x: boolean | undefined) => void;
  setCheckboxList?: (x: Array<any> | undefined) => void;
  setExportingData?: (x: Array<any> | undefined) => void;
  placeholder: string;
  buttonText: string;
  handleButtonClick: (x: any) => void;
  handleDeleteItem?: (x: any) => void;
  buttonIsDisabled?: boolean;
}
const CustomTable = ({
  dataList,
  columnHeaders,
  columnHeaderIsSortableList,
  columnNames,
  columnTypes,
  columnWidth,
  searchFields,
  handleClickRow,
  exportHeaders,
  exprotData,
  exportFileName,
  setExportingData,
  placeholder,
  buttonText,
  handleButtonClick,
  handleDeleteItem,
  buttonIsDisabled,
}: TableInputProps) => {
  const [sortItem, setSortItem] = useState('');
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc' | undefined>(
    'desc'
  );
  const [searchString, setSearchString] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLength, setPageLength] = useState<number | 0>(1);
  const [currentList, setCurrentList] = useState<Array<any>>([]);

  const updateCurrentList = useCallback(() => {
    // filtering
    const filteredList =
      searchString === ''
        ? dataList
        : dataList?.filter((item) =>
            searchFields?.some((field) =>
              item[field]?.toLowerCase().includes(searchString)
            )
          );
    // sorting
    const sortedList = _.orderBy(
      filteredList,
      [(list) => list[sortItem as string]],
      sortOrder
    );
    // paginating
    const paginatedList = paginate(sortedList, pageNumber, pageSize);
    setPageLength(filteredList?.length || 0);
    // ********************* partial or complete data fetching
    setCurrentList(paginatedList);

    if (setExportingData) {
      setExportingData(sortedList);
    }
  }, [
    dataList,
    pageSize,
    pageNumber,
    searchFields,
    searchString,
    sortOrder,
    sortItem,
    setExportingData,
  ]);
  useEffect(() => {
    updateCurrentList();
  }, [updateCurrentList]);
  const getColumnData = (i: number) => {
    let filteredList = currentList.filter((item, index) => index === i)[0];
    let list: Array<any> = [];
    columnNames.forEach((item) => {
      if (item === 'checkbox') {
        list.push('checkbox');
      } else {
        list.push(filteredList[item]);
      }
    });
    return list;
  };
  const generateField = (
    value: any,
    colIndex: number,
    rowIndex: number,
    handleClickDelete: ((item: any) => void) | any
  ) => {
    let type = columnTypes[colIndex];
    switch (type) {
      case 'url':
        return <Image src={value} alt={''} />;
      case 'money':
        return '$' + value.toFixed(2);
      case 'percent':
        return value + '%';
      case 'string':
        return value;
      case 'boolean':
        return <Checkbox checked={value} disabled />;
      case 'delete':
        return (
          <IconWrapper>
            <Icon icon={faTrashCan} onClick={() => handleClickDelete(value)} />
          </IconWrapper>
        );
      default:
        return value;
    }
  };
  const handleSorting = (index: number) => {
    if (!columnHeaderIsSortableList[index]) {
      return;
    }
    setSortItem(columnNames[index]);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  const displaySortIcons = (index: number) => {
    if (sortItem === columnNames[index]) {
      return (
        <span className='fa-stack fa-1x'>
          {sortOrder === 'asc' && (
            <SortWrapper>
              <FontAwesomeIcon icon={faSortUp} />
            </SortWrapper>
          )}
          {sortOrder === 'desc' && (
            <SortWrapper>
              <FontAwesomeIcon icon={faSortDown} />
            </SortWrapper>
          )}
        </span>
      );
    } else {
      return (
        <SortWrapper>
          <FontAwesomeIcon icon={faSort} />
        </SortWrapper>
      );
    }
  };
  return (
    <Wrapper>
      <SearchWrapper>
        <TextField
          id='search'
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder={placeholder}
        />
        <CustomButton onClick={handleButtonClick} disabled={buttonIsDisabled}>
          {buttonText}
        </CustomButton>
      </SearchWrapper>
      <TableWrapper>
        <Table>
          <THead>
            <TrHead>
              {columnHeaders.map((headerTitle, index) => (
                <Th
                  width={columnWidth[index]}
                  key={index}
                  onClick={() => handleSorting(index)}
                >
                  {headerTitle === 'export' ? (
                    <CSVLink
                      data={exprotData || []}
                      headers={exportHeaders}
                      filename={`${exportFileName}.csv`}
                      //   disabled={true}
                    >
                      <CustomButton disabled={false}>
                        Export as CSV
                      </CustomButton>
                    </CSVLink>
                  ) : (
                    headerTitle
                  )}

                  {columnHeaderIsSortableList[index] && (
                    <span>{displaySortIcons(index)}</span>
                  )}
                </Th>
              ))}
            </TrHead>
          </THead>
          <TBody>
            {currentList.map((item, rowIndex) => (
              <Tr key={rowIndex} hasBeenSeen={item['has_been_seen']}>
                {getColumnData(rowIndex).map((val, colIndex) =>
                  columnTypes[colIndex] === 'delete' ? (
                    <Td key={colIndex} width={columnWidth[colIndex]}>
                      {generateField(
                        item,
                        colIndex,
                        rowIndex,
                        handleDeleteItem
                      )}
                    </Td>
                  ) : (
                    <Td
                      key={colIndex}
                      width={columnWidth[colIndex]}
                      onClick={() => handleClickRow(item)}
                      align={
                        columnTypes[colIndex] === 'boolean' ? 'center' : 'left'
                      }
                    >
                      {generateField(val, colIndex, rowIndex, null)}
                    </Td>
                  )
                )}
              </Tr>
            ))}
          </TBody>
        </Table>
      </TableWrapper>
      <PaginationControl
        pageSize={pageSize}
        pageNumber={pageNumber}
        dataLength={pageLength}
        setPageSize={setPageSize}
        setPageNumber={setPageNumber}
      />
    </Wrapper>
  );
};

export default CustomTable;
