import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCity,
  getCityById,
  addCity,
  updateCity,
  deleteCity,
} from './cityAsyncActions';
import { history } from '../../../utils/history';
// import { AxiosResponse } from 'axios';

// type AllCityActionType = PayloadAction<
//   AxiosResponse<any, any> | undefined,
//   string,
//   { arg: void; cityId: string; cityStatus: 'fulfilled' },
//   never
// >;

export interface CityType {
  city_id?: number | null;
  city_name: string;
}
export interface CityStateType {
  city: CityType | null | undefined;
  allCity: Array<CityType> | null;
  isGettingCity: boolean;
}
const initialState: CityStateType = {
  city: null,
  allCity: [],
  isGettingCity: false,
};
export const citySlice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    initiateCity: (state) => {
      state.city = { city_id: null, city_name: '' };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCity?.pending, (state) => {
      state.isGettingCity = true;
    });
    builder.addCase(getAllCity?.fulfilled, (state, action: any) => {
      state.isGettingCity = false;
      state.allCity = action?.payload?.data.data;
    });
    builder.addCase(getAllCity.rejected, (state, action) => {
      state.isGettingCity = false;
    });
    // getCityById
    builder.addCase(getCityById?.pending, (state) => {
      state.isGettingCity = true;
    });
    builder.addCase(getCityById?.fulfilled, (state, action: any) => {
      state.isGettingCity = false;
      state.city = action?.payload?.data.data as CityType;
    });
    builder.addCase(getCityById.rejected, (state, action: any) => {
      state.isGettingCity = false;
    });
    // addCity
    builder.addCase(addCity?.pending, (state) => {
      state.isGettingCity = true;
    });
    builder.addCase(addCity?.fulfilled, (state, action: any) => {
      state.isGettingCity = false;
      history.navigate('/city', {
        state: {
          id: null,
          pageTitle: 'City List',
          referencePath: '/city',
          referencePageTitle: 'City List',
        },
      });
    });
    builder.addCase(addCity.rejected, (state, action: any) => {
      state.isGettingCity = false;
    });
    // updateCity
    builder.addCase(updateCity?.pending, (state) => {
      state.isGettingCity = true;
    });
    builder.addCase(updateCity?.fulfilled, (state, action: any) => {
      state.isGettingCity = false;
      history.navigate('/city', {
        state: {
          id: null,
          pageTitle: 'City List',
          referencePath: '/city',
          referencePageTitle: 'City List',
        },
      });
    });
    builder.addCase(updateCity.rejected, (state, action: any) => {
      state.isGettingCity = false;
    });
    // deleteCity
    builder.addCase(deleteCity?.pending, (state) => {
      state.isGettingCity = true;
    });
    builder.addCase(deleteCity?.fulfilled, (state, action: any) => {
      state.isGettingCity = false;
      history.navigate('/city', {
        state: {
          id: null,
          pageTitle: 'City List',
          referencePath: '/city',
          referencePageTitle: 'City List',
        },
      });
    });
    builder.addCase(deleteCity.rejected, (state, action: any) => {
      state.isGettingCity = false;
    });
  },
});
export const { initiateCity } = citySlice.actions;
const cityReducer = citySlice.reducer;
export default cityReducer;
