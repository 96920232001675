import React, { useEffect, useState } from 'react';
import { Wrapper } from './approachListContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import {} from '../approachAsyncActions';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../../components/CustomTable';
import CustomConfirmationModal from '../../../../components/CustomConfirmationModal';
import { getAllApproaches, deleteApproach } from '../approachAsyncActions';
import { getAllSubcategory } from '../../subcategory/subcategoryAsyncActions';
function ApproachListContainer() {
  const navigate = useNavigate();
  const columnHeaders = ['ID', 'Step', 'Title'];
  const columnNames = ['approach_id', 'step', 'title'];
  const columnTypes = ['string', 'string', 'string'];
  const columnWidth = ['60px', '110px', 'auto'];
  const searchFileds = ['title'];
  const columnHeaderIsSortableList = [true, true, true];
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>();
  const { allApproaches } = useAppSelector((state) => state.approachReducer);
  useEffect(() => {
    dispatch(getAllSubcategory());
    dispatch(getAllApproaches());
  }, [dispatch]);
  const handleClickRow = (item: any) => {
    navigate('/approach-detail', {
      state: {
        id: item?.approach_id?.toString(),
        pageTitle: 'Edit Approach',
        referencePath: '/approach',
        referencePageTitle: 'Approach List',
      },
    });
  };
  const handleButtonClick = () => {
    navigate('/approach-detail', {
      state: {
        id: null,
        pageTitle: 'Create Approach',
        referencePath: '/approach',
        referencePageTitle: 'Approach List',
      },
    });
  };
  const handleDeleteItem = (item: any) => {
    setSelectedDeleteId(item.approach_id);
    setIsOpen(true);
  };
  const handleSubmitDelete = () => {
    if (selectedDeleteId) {
      dispatch(deleteApproach(selectedDeleteId));
    }
    setIsOpen(false);
  };
  return (
    <Wrapper>
      <CustomTable
        dataList={allApproaches}
        columnHeaders={columnHeaders}
        columnHeaderIsSortableList={columnHeaderIsSortableList}
        columnNames={columnNames}
        columnWidth={columnWidth}
        columnTypes={columnTypes}
        placeholder='Enter type'
        handleClickRow={handleClickRow}
        handleButtonClick={handleButtonClick}
        buttonText={'Create Approach'}
        searchFields={searchFileds}
        handleDeleteItem={handleDeleteItem}
        buttonIsDisabled={true}
      />
      {isOpen && (
        <CustomConfirmationModal
          title={'Confirm Delete Approach'}
          message={
            'By clicking confiming this approach will be removed permanently. \nIf you are sure press confirm.'
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleAction={handleSubmitDelete}
        />
      )}
    </Wrapper>
  );
}

export default ApproachListContainer;
