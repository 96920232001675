import { FC } from 'react';
import CustomButton from './CustomButton';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface ModalInputProps {
  title: string;
  message: string;
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  handleAction: () => void;
}
const CustomConfirmationModal: FC<ModalInputProps> = ({
  title,
  message,
  isOpen,
  setIsOpen,
  handleAction,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: '20px 30px' }}>
        <CustomButton onClick={() => setIsOpen(false)}>Cancel</CustomButton>
        <CustomButton onClick={handleAction}>Confirm</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default CustomConfirmationModal;
