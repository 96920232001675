import { createSlice } from '@reduxjs/toolkit';
import {
  getAllFindUs,
  getFindUsById,
  addFindUs,
  updateFindUs,
  deleteFindUs,
} from './findUsAsyncActions';
import { history } from '../../../utils/history';
// import { AxiosResponse } from 'axios';

// type AllFindUsActionType = PayloadAction<
//   AxiosResponse<any, any> | undefined,
//   string,
//   { arg: void; findUsId: string; findUsStatus: 'fulfilled' },
//   never
// >;

export interface FindUsType {
  find_us_id?: number | null;
  find_us_type: string;
}
export interface FindUsStateType {
  findUs: FindUsType | null | undefined;
  allFindUs: Array<FindUsType> | null;
  isGettingFindUs: boolean;
}
const initialState: FindUsStateType = {
  findUs: null,
  allFindUs: [],
  isGettingFindUs: false,
};
export const findUsSlice = createSlice({
  name: 'findUs',
  initialState,
  reducers: {
    initiateFindUs: (state) => {
      state.findUs = { find_us_id: null, find_us_type: '' };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFindUs?.pending, (state) => {
      state.isGettingFindUs = true;
    });
    builder.addCase(getAllFindUs?.fulfilled, (state, action: any) => {
      state.isGettingFindUs = false;
      state.allFindUs = action?.payload?.data.data;
    });
    builder.addCase(getAllFindUs.rejected, (state, action) => {
      state.isGettingFindUs = false;
    });
    // getFindUsById
    builder.addCase(getFindUsById?.pending, (state) => {
      state.isGettingFindUs = true;
    });
    builder.addCase(getFindUsById?.fulfilled, (state, action: any) => {
      state.isGettingFindUs = false;
      state.findUs = action?.payload?.data.data as FindUsType;
    });
    builder.addCase(getFindUsById.rejected, (state, action: any) => {
      state.isGettingFindUs = false;
    });
    // addFindUs
    builder.addCase(addFindUs?.pending, (state) => {
      state.isGettingFindUs = true;
    });
    builder.addCase(addFindUs?.fulfilled, (state, action: any) => {
      state.isGettingFindUs = false;
      history.navigate('/findus', {
        state: {
          id: null,
          pageTitle: 'Find Us List',
          referencePath: '/findus',
          referencePageTitle: 'Find Us List',
        },
      });
    });
    builder.addCase(addFindUs.rejected, (state, action: any) => {
      state.isGettingFindUs = false;
    });
    // updateFindUs
    builder.addCase(updateFindUs?.pending, (state) => {
      state.isGettingFindUs = true;
    });
    builder.addCase(updateFindUs?.fulfilled, (state, action: any) => {
      state.isGettingFindUs = false;
      history.navigate('/findus', {
        state: {
          id: null,
          pageTitle: 'Find Us List',
          referencePath: '/findus',
          referencePageTitle: 'Find Us List',
        },
      });
    });
    builder.addCase(updateFindUs.rejected, (state, action: any) => {
      state.isGettingFindUs = false;
    });
    // deleteFindUs
    builder.addCase(deleteFindUs?.pending, (state) => {
      state.isGettingFindUs = true;
    });
    builder.addCase(deleteFindUs?.fulfilled, (state, action: any) => {
      state.isGettingFindUs = false;
      history.navigate('/findus', {
        state: {
          id: null,
          pageTitle: 'Find Us List',
          referencePath: '/findus',
          referencePageTitle: 'Find Us List',
        },
      });
    });
    builder.addCase(deleteFindUs.rejected, (state, action: any) => {
      state.isGettingFindUs = false;
    });
  },
});
export const { initiateFindUs } = findUsSlice.actions;
const findUsReducer = findUsSlice.reducer;
export default findUsReducer;
