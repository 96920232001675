import CustomButton from '../../../../components/CustomButton';
import transformToFormData from '../../../../utils/transformToFormData';
import { addGallery, updateGallery } from '../galleryAsyncActions';
import {
  TextField,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ModalImage from 'react-modal-image';
import { withFormik, FormikProps } from 'formik';
import { SubcategoryType } from '../../subcategory/subcategorySlice';
import * as Yup from 'yup';
import {
  Wrapper,
  FormTitle,
  FieldsWrapper,
  FieldWrapper,
  HeaderWrapper,
  ButtonWrapper,
  CustomerImgWrapper,
} from './galleryDetailFormStyles';
import { GalleryType } from '../gallerySlice';
import { AppDispatch } from '../../../../store/store';
import { useAppDispatch } from '../../../../store/reduxHooks';
import CustomFileUpload from '../../../../components/CustomFileUpload';

const Root_Static_URL = process.env.REACT_APP_STATIC_ROOT_URL;
interface GalleryDetailFormPropsType {
  gallery?: GalleryType | null;
  location: { pathname: string; state: any };
  allSubcategory: Array<SubcategoryType> | null;
}
interface FormValues {
  gallery_id?: number | null;
  title?: string | null;
  subtitle?: string | null;
  description: string;
  media: string | null;
  media_thumb: string;
  is_main: boolean;
  subcategory_id: number | null;
}

interface MyFormProps {
  gallery?: GalleryType | null;
  dispatch: AppDispatch;
  location?: { pathname: string; state: any };
  allSubcategory: Array<SubcategoryType> | null;
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
interface OtherPropsType {
  gallery?: GalleryType | null;
  location?: { pathname: string; state: any };
  allSubcategory: Array<SubcategoryType> | null;
  dispatch: AppDispatch;
  transformToFormData: (
    data: any,
    formData: FormData,
    parentKey: string | null
  ) => FormData;
}
const InnerForm = (props: OtherPropsType & FormikProps<FormValues>) => {
  const {
    // formik props
    values,
    errors,
    dirty,
    // component props
    allSubcategory,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <>{console.log('valuse', values)}</>
      <>{console.log('error', errors)}</>
      <HeaderWrapper>
        <FormTitle id='alert-dialog-title'></FormTitle>
      </HeaderWrapper>
      <FieldsWrapper>
        <div>
          {props.gallery?.gallery_id && (
            <FieldWrapper>
              <TextField
                type='text'
                size='small'
                label='Gallery ID'
                fullWidth
                value={props.gallery?.gallery_id}
                disabled
              />
            </FieldWrapper>
          )}
          <FieldWrapper>
            <Autocomplete
              size='small'
              fullWidth
              limitTags={2}
              disablePortal
              id='combo-box-demo'
              options={allSubcategory || []}
              value={allSubcategory?.find(
                (item: SubcategoryType) =>
                  values.subcategory_id === item?.subcategory_id
              )}
              getOptionLabel={(option) => option?.subcategory_name}
              onChange={(_, selectedItem) => {
                setFieldValue('subcategory_id', selectedItem?.subcategory_id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Subcategory'
                  required
                  error={errors?.subcategory_id ? true : false}
                  helperText={errors?.subcategory_id}
                />
              )}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              required
              type='text'
              fullWidth
              size='small'
              name='title'
              label='Gallery Title'
              value={values.title}
              onChange={handleChange}
              error={errors?.title ? true : false}
              helperText={errors?.title}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              multiline
              rows={10}
              size='small'
              name='subtitle'
              label='Gallery Subtitle'
              value={values.subtitle}
              onChange={handleChange}
              error={errors?.subtitle ? true : false}
              helperText={errors?.subtitle}
            />
          </FieldWrapper>
          <FieldWrapper>
            <CustomFileUpload
              handleUploadFile={(e) => {
                if (e.target.files) {
                  const newFile = e.target.files[0];
                  if (newFile) {
                    setFieldValue('media', newFile);
                  }
                }
              }}
              handleClearFile={() => setFieldValue('media', '')}
              fileName={values.media}
              placehoder='Gallerye Image'
              setFieldValue={setFieldValue}
            >
              Main Image
            </CustomFileUpload>
            {typeof values.media === 'string' && values.media && (
              <CustomerImgWrapper>
                <ModalImage
                  small={`${Root_Static_URL}/gallery/${values.media}?size=800`}
                  large={`${Root_Static_URL}/gallery/${values.media}?size=800`}
                  alt='Cutomer Space 3'
                />
              </CustomerImgWrapper>
            )}
            {errors.media && (
              <div
                style={{
                  color: 'red',
                  textAlign: 'left',
                  marginTop: '8px',
                  marginLeft: '5px',
                  width: '100%',
                  fontSize: '12px',
                }}
              >
                {errors.media}
              </div>
            )}
          </FieldWrapper>
        </div>
        <div>
          <FieldWrapper>
            <FormControlLabel
              style={{
                padding: 0,
                margin: 0,
                letterSpacing: '2px',
              }}
              name='is_main'
              checked={values.is_main}
              control={
                <Checkbox
                  sx={{
                    // color: '#000',
                    '&.Mui-checked': {
                      color: '#000',
                    },
                  }}
                />
              }
              label='Is in home page gallery list?'
              onChange={handleChange}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              type='text'
              fullWidth
              multiline
              rows={15}
              size='small'
              name='description'
              label='Gallery Description'
              value={values.description}
              onChange={handleChange}
              error={errors?.description ? true : false}
              helperText={errors?.description}
            />
          </FieldWrapper>

          <FieldWrapper>
            <CustomFileUpload
              handleUploadFile={(e) => {
                if (e.target.files) {
                  const newFile = e.target.files[0];
                  if (newFile) {
                    setFieldValue('media_thumb', newFile);
                  }
                }
              }}
              handleClearFile={() => setFieldValue('media_thumb', '')}
              fileName={values.media_thumb}
              placehoder='Gallerye Image'
              setFieldValue={setFieldValue}
            >
              Thumbnail Image
            </CustomFileUpload>
            {typeof values.media_thumb === 'string' && values.media_thumb && (
              <CustomerImgWrapper>
                <ModalImage
                  small={`${Root_Static_URL}/gallery/${values.media_thumb}?size=800`}
                  large={`${Root_Static_URL}/gallery/${values.media_thumb}?size=800`}
                  alt='Cutomer Space 3'
                />
              </CustomerImgWrapper>
            )}
            {errors.media_thumb && (
              <div
                style={{
                  color: 'red',
                  textAlign: 'left',
                  marginTop: '8px',
                  marginLeft: '5px',
                  width: '100%',
                  fontSize: '12px',
                }}
              >
                {errors.media_thumb}
              </div>
            )}
          </FieldWrapper>
        </div>
      </FieldsWrapper>
      <ButtonWrapper>
        <CustomButton
          type='submit'
          disabled={Object.keys(errors).length > 0 || !dirty}
        >
          Submit Gallery
        </CustomButton>
      </ButtonWrapper>
    </form>
  );
};
const validFileExtensions = ['webp'];
const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  media: Yup.mixed()
    .required('Required')
    .test('is-valid-type', 'Not a valid image type', (value) => {
      let isValid = true;
      if (value instanceof File) {
        const selectedFileType = value.name.split('.').pop();
        isValid = validFileExtensions.includes(selectedFileType as string);
        console.log('1', isValid, selectedFileType);
      } else {
        if (typeof value === 'string') {
          const selectedFileType = value?.split('.').pop();
          isValid = validFileExtensions.includes(selectedFileType as string);
          console.log('2', isValid, value);
        }
      }
      console.log('3', isValid);
      return isValid;
    }),
  media_thumb: Yup.mixed()
    .required('Required')
    .test('is-valid-type', 'Not a valid image type', (value) => {
      let isValid = true;
      if (value instanceof File) {
        const selectedFileType = value.name.split('.').pop();
        isValid = validFileExtensions.includes(selectedFileType as string);
        console.log('1', isValid, selectedFileType);
      } else {
        if (typeof value === 'string') {
          const selectedFileType = value?.split('.').pop();
          isValid = validFileExtensions.includes(selectedFileType as string);
          console.log('2', isValid, value);
        }
      }
      console.log('3', isValid);
      return isValid;
    }),
  is_main: Yup.boolean().required('Required'),
  subcategory_id: Yup.number().required('Required'),
});
const GalleryForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: (props) => ({
    title: props.gallery?.title || '',
    subtitle: props.gallery?.subtitle || '',
    description: props.gallery?.description || '',
    media: props.gallery?.media || '',
    media_thumb: props.gallery?.media_thumb || '',
    is_main: props.gallery?.is_main || false,
    subcategory_id: props.gallery?.subcategory_id || null,
  }),
  validationSchema,
  enableReinitialize: true,
  async handleSubmit(values: FormValues, { props }) {
    var bodyFormData = new FormData();
    let newValues = { ...values };
    var newFormData = props.transformToFormData(newValues, bodyFormData, null);
    if (props.gallery?.gallery_id) {
      const gallery = {
        id: props.gallery?.gallery_id,
        payload: newFormData,
      };
      props.dispatch(updateGallery(gallery));
    } else {
      const gallery = newFormData;
      props.dispatch(addGallery(gallery));
    }
  },
})(InnerForm);
function GalleryDetailForm({
  gallery,
  location,
  allSubcategory,
}: GalleryDetailFormPropsType) {
  const dispatch = useAppDispatch();
  return (
    <Wrapper>
      <GalleryForm
        transformToFormData={transformToFormData}
        location={location}
        gallery={gallery}
        allSubcategory={allSubcategory}
        dispatch={dispatch}
      />
    </Wrapper>
  );
}

export default GalleryDetailForm;
