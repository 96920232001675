import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { displayAlert } from '../../auth/authSlice';
const root_url = process.env.REACT_APP_ROOT_URL;

export const getRequestById = createAsyncThunk(
  'request/getRequestById',
  async (id: number, thunkApi) => {
    try {
      const res = await api.get(`${root_url}/request/find/${id}`);
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response.status === 500) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const getAllRequest = createAsyncThunk(
  'request/getAllRequest',
  async (_, thunkApi) => {
    try {
      const res = await api.get(`${root_url}/request/find`);
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response.status === 500) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const addRequest = createAsyncThunk(
  'request/addRequest',
  async (payload: FormData, thunkApi) => {
    try {
      const res = await api.post(`${root_url}/request/add`, payload);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: `Request was successfully added.`,
        })
      );
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response.status === 500) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const updateRequest = createAsyncThunk(
  'request/updateRequest',
  async ({ id, payload }: any, thunkApi) => {
    try {
      const res = await api.put(`${root_url}/request/update/${id}`, payload);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: `Request was successfully updated.`,
        })
      );
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response.status === 500) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
export const deleteRequest = createAsyncThunk(
  'request/deleteRequest',
  async (id: number, thunkApi) => {
    try {
      const res = await api.delete(`${root_url}/request/delete/${id}`);
      thunkApi.dispatch(
        displayAlert({
          isAlertDispalyed: true,
          alertType: 'success',
          alertMessage: res.data.data,
        })
      );
      thunkApi.dispatch(getAllRequest());
      return res;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Network Error (Cors Origin)',
          })
        );
      } else if (error?.response.status === 500) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else if (error?.response?.data) {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: error.response.data.message,
          })
        );
        return thunkApi.rejectWithValue(error.response.data);
      } else {
        thunkApi.dispatch(
          displayAlert({
            isAlertDispalyed: true,
            alertType: 'error',
            alertMessage: 'Something went wrong: Unkown Reason',
          })
        );
      }
    }
  }
);
