import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 30px 10px;
  min-height: 700px;
  /* max-width: 1000px; */

  /* Medium devices (landscape tablets, 768px and up) */
  /* @media only screen and (max-width: 768px) {
    max-width: 750px;
  } */

  /* Large devices (laptops/desktops, 992px and up) */
  /* @media only screen and (min-width: 992px) {
    max-width: 780px;
  } */
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  /* @media only screen and (min-width: 1180px) {
    max-width: 1000px;
  } */
`;
export const ContentWrapper = styled.div`
  background-color: #fff;
  padding: 30px 30px;
  background-color: #fff;
  border-radius: 10px;
`;
