import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper } from './cityDetailContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getCityById } from '../cityAsyncActions';
import CityDetailForm from './CityDetailForm';
import { initiateCity } from '../citySlice';
function CityDetailContainer() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { city } = useAppSelector((state) => state.cityReducer);
  useEffect(() => {
    const id = location.state?.id;
    if (id) {
      dispatch(getCityById(id));
    } else {
      dispatch(initiateCity());
    }
  }, [dispatch, location?.state]);

  return (
    <Wrapper>
      <CityDetailForm city={city} location={location} />
    </Wrapper>
  );
}

export default CityDetailContainer;
