import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper } from './jumbotronDetailContainerStyles';
import { useAppDispatch, useAppSelector } from '../../../../store/reduxHooks';
import { getJumbotronById } from '../jumbotronAsyncActions';
import JumbotronDetailForm from './JumbotronDetailForm';
import { initiateJumbotron } from '../jumbotronSlice';
function JumbotronDetailContainer() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { jumbotron } = useAppSelector((state) => state.jumbotronReducer);
  const { allSubcategory } = useAppSelector(
    (state) => state.subcategoryReducer
  );
  useEffect(() => {
    const id = location.state?.id;

    if (id) {
      dispatch(getJumbotronById(id));
    } else {
      dispatch(initiateJumbotron());
    }
  }, [dispatch, location?.state]);

  return (
    <Wrapper>
      {jumbotron && (
        <JumbotronDetailForm
          jumbotron={jumbotron}
          location={location}
          allSubcategory={allSubcategory}
        />
      )}
    </Wrapper>
  );
}

export default JumbotronDetailContainer;
