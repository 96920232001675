import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { login } from './authAsyncActions';
import { AxiosResponse } from 'axios';
import { AlertColor } from '@mui/material/Alert';

interface LoginObjType {
  username: string;
  password: string;
}
type ActionType = PayloadAction<
  AxiosResponse<any, any>,
  string,
  {
    arg: LoginObjType;
    requestId: string;
    requestStatus: 'fulfilled';
  },
  never
>;
interface UserArrayType {
  first_name: string;
  last_name: string;
  role: string;
  username: string;
  password: string;
}
interface UserObjType {
  first_name: string;
  last_name: string;
  role: string;
  username: string;
  password: string;
}
export interface userStateType {
  user: UserObjType | null;
  allUsers: Array<UserArrayType> | null;
  isGettingLoggedIn: boolean;
  token?: string | null;
  error?: string | null | unknown;
  isAlertDispalyed?: boolean;
  alertType: AlertColor;
  alertMessage: string;
}
const initialState: userStateType = {
  user: null,
  allUsers: [],
  isGettingLoggedIn: false,
  token: null,
  error: '',
  isAlertDispalyed: false,
  alertType: 'success',
  alertMessage: '',
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('token');
      state.token = null;
    },
    closeAlert: (state) => {
      state.isAlertDispalyed = false;
    },
    displayAlert: (
      state,
      action: PayloadAction<{
        isAlertDispalyed: boolean;
        alertType: AlertColor;
        alertMessage: string;
      }>
    ) => {
      state.alertType = action.payload.alertType;
      state.alertMessage = action.payload.alertMessage;
      state.isAlertDispalyed = action.payload.isAlertDispalyed;
      console.log('display ALret=>', action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isGettingLoggedIn = true;
    });
    builder.addCase(login.fulfilled, (state, action: ActionType) => {
      state.isGettingLoggedIn = false;
      state.token = action.payload?.data?.access_token;
      localStorage.setItem('token', action.payload?.data?.access_token);
      //
      state.alertType = 'success';
      state.isAlertDispalyed = true;
      state.alertMessage = 'Welcome to the portal!';
    });
    builder.addCase(login.rejected, (state, action: any) => {
      state.isGettingLoggedIn = false;
      //
      state.alertType = 'error';
      state.isAlertDispalyed = true;
      state.alertMessage = `${action?.payload?.message}`;
    });
  },
});
export const { logout, closeAlert, displayAlert } = authSlice.actions;
const authReducer = authSlice.reducer;
export default authReducer;
