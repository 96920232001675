import { createSlice } from '@reduxjs/toolkit';
import {
  getAllWelcome,
  getWelcomeById,
  addWelcome,
  updateWelcome,
  deleteWelcome,
} from './welcomeAsyncActions';
import { history } from '../../../utils/history';
export interface WelcomeType {
  welcome_id: number | null;
  title: string;
  subtitle: string;
  description: string;
  subdescription: string;
  media: string;
}
export interface WelcomeStateType {
  welcome: WelcomeType | null | undefined;
  allWelcomes: Array<WelcomeType> | null;
  isGettingWelcome: boolean;
}
const initialState: WelcomeStateType = {
  welcome: null,
  allWelcomes: [],
  isGettingWelcome: false,
};
export const welcomeSlice = createSlice({
  name: 'welcome',
  initialState,
  reducers: {
    initiateWelcome: (state) => {
      state.welcome = {
        welcome_id: null,
        title: '',
        subtitle: '',
        description: '',
        subdescription: '',
        media: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllWelcome?.pending, (state) => {
      state.isGettingWelcome = true;
    });
    builder.addCase(getAllWelcome?.fulfilled, (state, action: any) => {
      state.isGettingWelcome = false;
      state.allWelcomes = action?.payload?.data.data;
    });
    builder.addCase(getAllWelcome.rejected, (state, action) => {
      state.isGettingWelcome = false;
    });
    // getWelcomeById
    builder.addCase(getWelcomeById?.pending, (state) => {
      state.isGettingWelcome = true;
    });
    builder.addCase(getWelcomeById?.fulfilled, (state, action: any) => {
      state.isGettingWelcome = false;
      state.welcome = action?.payload?.data.data as WelcomeType;
    });
    builder.addCase(getWelcomeById.rejected, (state, action: any) => {
      state.isGettingWelcome = false;
    });
    // addWelcome
    builder.addCase(addWelcome?.pending, (state) => {
      state.isGettingWelcome = true;
    });
    builder.addCase(addWelcome?.fulfilled, (state, action: any) => {
      state.isGettingWelcome = false;
      history.navigate('/welcome', {
        state: {
          id: null,
          pageTitle: 'Welcome List',
          referencePath: '/welcome',
          referencePageTitle: 'Welcome List',
        },
      });
    });
    builder.addCase(addWelcome.rejected, (state, action: any) => {
      state.isGettingWelcome = false;
    });
    // updateWelcome
    builder.addCase(updateWelcome?.pending, (state) => {
      state.isGettingWelcome = true;
    });
    builder.addCase(updateWelcome?.fulfilled, (state, action: any) => {
      state.isGettingWelcome = false;
      history.navigate('/welcome', {
        state: {
          id: null,
          pageTitle: 'Welcome List',
          referencePath: '/welcome',
          referencePageTitle: 'Welcome List',
        },
      });
    });
    builder.addCase(updateWelcome.rejected, (state, action: any) => {
      state.isGettingWelcome = false;
    });
    // deleteWelcome
    builder.addCase(deleteWelcome?.pending, (state) => {
      state.isGettingWelcome = true;
    });
    builder.addCase(deleteWelcome?.fulfilled, (state, action: any) => {
      state.isGettingWelcome = false;
      history.navigate('/welcome', {
        state: {
          id: null,
          pageTitle: 'Welcome List',
          referencePath: '/welcome',
          referencePageTitle: 'Welcome List',
        },
      });
    });
    builder.addCase(deleteWelcome.rejected, (state, action: any) => {
      state.isGettingWelcome = false;
    });
  },
});
export const { initiateWelcome } = welcomeSlice.actions;
const welcomeReducer = welcomeSlice.reducer;
export default welcomeReducer;
