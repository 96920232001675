import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;
const PaginationWrapper = styled.div`
  display: grid;
  grid-template-columns: 280px 180px;
  grid-gap: 20px;
  background-color: #f5f5f5;
  padding: 5px;
  border-radius: 8px;
`;
const PageSelectionTitle = styled.div`
  width: 200px;
  font-size: 20px;
  font-weight: 500;
`;
const PageSizeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const PageInfoWrapper = styled.div``;
const PageControlWrapper = styled.div`
  display: grid;
  grid-template-columns: 26px 1fr 26px;
  grid-gap: 15px;
  align-items: center;
  width: 100%;
`;
const CustomIcon = styled<any>(FontAwesomeIcon)`
  cursor: ${(props) =>
    props.islastpage === 'true' || props.isfirstpage === 'true'
      ? 'default'
      : 'pointer'};
  color: ${(props) =>
    props.islastpage === 'true' || props.isfirstpage === 'true'
      ? 'lightgray'
      : '#000000'};
  font-size: 25px;
`;
interface PahinationInputProps {
  pageSize: number;
  pageNumber: number;
  dataLength: number | 0;
  pageInterval?: Array<number>;
  setPageSize: (pageSize: number) => void;
  setPageNumber: (pageNumber: number) => void;
}
const PaginationControl: FC<PahinationInputProps> = ({
  pageSize,
  pageNumber,
  dataLength,
  pageInterval,
  setPageSize,
  setPageNumber,
}) => {
  const intervals = pageInterval || [10, 25];
  const [totalPageNumber, setTotalPageNumber] = useState<number>(0);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setPageNumber(1);
  };
  useEffect(() => {
    const fractionPageNumber = dataLength / pageSize;
    let newTotalPageNumber: number;
    if (dataLength % pageSize === 0) {
      newTotalPageNumber = parseInt(fractionPageNumber.toString());
    } else {
      newTotalPageNumber = parseInt(fractionPageNumber.toString()) + 1;
    }
    if (pageNumber === newTotalPageNumber) {
      setIsLastPage(true);
    } else {
      setIsLastPage(false);
    }
    setTotalPageNumber(newTotalPageNumber);
    window.scrollTo(0, 0);
  }, [pageSize, dataLength, pageNumber]);

  const handleIncreasePage = () => {
    if (!isLastPage) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handleDecreasePage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <Wrapper>
      <PaginationWrapper>
        <PageSizeWrapper>
          <PageSelectionTitle>Select Page Size:</PageSelectionTitle>
          <Select
            value={pageSize || 10}
            onChange={handlePageSizeChange}
            size='small'
            style={{ width: '80px' }}
          >
            {intervals.map((size, index) => (
              <MenuItem key={index} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </PageSizeWrapper>
        <PageControlWrapper>
          <CustomIcon
            icon={faChevronLeft}
            isfirstpage={pageNumber === 1 ? 'true' : 'false'}
            onClick={handleDecreasePage}
          />
          <PageInfoWrapper>{`${pageNumber} of ${totalPageNumber}`}</PageInfoWrapper>
          <CustomIcon
            icon={faChevronRight}
            islastpage={isLastPage.toString()}
            onClick={handleIncreasePage}
          />
        </PageControlWrapper>
      </PaginationWrapper>
    </Wrapper>
  );
};

export default PaginationControl;
