import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr 140px;
  margin: 0 !important;
`;
export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding-left: 20px;
`;
export const Logo = styled.img`
  width: 100%;
`;
export const SignoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
`;
export const SignoutButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #e4cd84;
  letter-spacing: 0.5px;
  &:hover {
    color: #ffce2e;
  }
  cursor: pointer;
`;
