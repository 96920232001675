import { createSlice } from '@reduxjs/toolkit';
import {
  getAllADS,
  getADSById,
  addADS,
  updateADS,
  deleteADS,
} from './adsAsyncActions';
import { history } from '../../../utils/history';
// import { AxiosResponse } from 'axios';

// type AllADSActionType = PayloadAction<
//   AxiosResponse<any, any> | undefined,
//   string,
//   { arg: void; adsId: string; adsStatus: 'fulfilled' },
//   never
// >;

export interface ADSType {
  ads_id: number | null;
  font_size1: string;
  font_size2: string;
  font_size3: string;
  font_size4: string;
  font_size5: string;
  font_family1: string;
  font_family2: string;
  font_family3: string;
  font_family4: string;
  font_family5: string;
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  color5: string;
  font_weight1: string;
  font_weight2: string;
  font_weight3: string;
  font_weight4: string;
  font_weight5: string;
  margin1: string;
  margin2: string;
  margin3: string;
  margin4: string;
  margin5: string;
  is_blinking1: boolean;
  is_blinking2: boolean;
  is_blinking3: boolean;
  is_blinking4: boolean;
  is_blinking5: boolean;
  des1: string;
  des2: string;
  des3: string;
  des4: string;
  des5: string;
}
export interface ADSStateType {
  ads: ADSType | null | undefined;
  allADS: Array<ADSType> | null;
  isGettingADS: boolean;
}
const initialState: ADSStateType = {
  ads: null,
  allADS: [],
  isGettingADS: false,
};
export const adsSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    initiateADS: (state) => {
      state.ads = {
        ads_id: null,
        font_size1: '',
        font_size2: '',
        font_size3: '',
        font_size4: '',
        font_size5: '',
        font_family1: '',
        font_family2: '',
        font_family3: '',
        font_family4: '',
        font_family5: '',
        color1: '',
        color2: '',
        color3: '',
        color4: '',
        color5: '',
        font_weight1: '',
        font_weight2: '',
        font_weight3: '',
        font_weight4: '',
        font_weight5: '',
        margin1: '',
        margin2: '',
        margin3: '',
        margin4: '',
        margin5: '',
        is_blinking1: false,
        is_blinking2: false,
        is_blinking3: false,
        is_blinking4: false,
        is_blinking5: false,
        des1: '',
        des2: '',
        des3: '',
        des4: '',
        des5: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllADS?.pending, (state) => {
      state.isGettingADS = true;
    });
    builder.addCase(getAllADS?.fulfilled, (state, action: any) => {
      state.isGettingADS = false;
      state.allADS = action?.payload?.data.data;
    });
    builder.addCase(getAllADS.rejected, (state, action) => {
      state.isGettingADS = false;
    });
    // getADSById
    builder.addCase(getADSById?.pending, (state) => {
      state.isGettingADS = true;
    });
    builder.addCase(getADSById?.fulfilled, (state, action: any) => {
      state.isGettingADS = false;
      state.ads = action?.payload?.data.data as ADSType;
    });
    builder.addCase(getADSById.rejected, (state, action: any) => {
      state.isGettingADS = false;
    });
    // addADS
    builder.addCase(addADS?.pending, (state) => {
      state.isGettingADS = true;
    });
    builder.addCase(addADS?.fulfilled, (state, action: any) => {
      state.isGettingADS = false;
      history.navigate('/ads', {
        state: {
          id: null,
          pageTitle: 'ADS List',
          referencePath: '/ads',
          referencePageTitle: 'ADS List',
        },
      });
    });
    builder.addCase(addADS.rejected, (state, action: any) => {
      state.isGettingADS = false;
    });
    // updateADS
    builder.addCase(updateADS?.pending, (state) => {
      state.isGettingADS = true;
    });
    builder.addCase(updateADS?.fulfilled, (state, action: any) => {
      state.isGettingADS = false;
      history.navigate('/ads', {
        state: {
          id: null,
          pageTitle: 'ADS List',
          referencePath: '/ads',
          referencePageTitle: 'ADS List',
        },
      });
    });
    builder.addCase(updateADS.rejected, (state, action: any) => {
      state.isGettingADS = false;
    });
    // deleteADS
    builder.addCase(deleteADS?.pending, (state) => {
      state.isGettingADS = true;
    });
    builder.addCase(deleteADS?.fulfilled, (state, action: any) => {
      state.isGettingADS = false;
      history.navigate('/ads', {
        state: {
          id: null,
          pageTitle: 'ADS List',
          referencePath: '/ads',
          referencePageTitle: 'ADS List',
        },
      });
    });
    builder.addCase(deleteADS.rejected, (state, action: any) => {
      state.isGettingADS = false;
    });
  },
});
export const { initiateADS } = adsSlice.actions;
const adsReducer = adsSlice.reducer;
export default adsReducer;
